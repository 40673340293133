import React, {useState} from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import SignatureCanvas from "react-signature-canvas";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import {datetime, users} from "@atttomyx/shared-utils";
import * as wizardService from "../../services/wizards";
import {AFFILIATE, COMPANY_NAME} from "../../constants";
import "./analysisResultsDialog.css";

const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: "900px",
    },
}));

const AnalysisResultsDialog = (props) => {
    const {snackbar, user, onCancel, onSave} = props;
    const [canvas, setCanvas] = useState(null);
    const [signature, setSignature] = useState(null);
    const [saving, setSaving] = useState(false);

    const userName = users.getFullName(user);
    const affiliateName = users.getFullName(AFFILIATE);

    const onStrokeEnd = () => {
        if (canvas && !canvas.isEmpty()) {
            setSignature(canvas.toDataURL());
        }
    };

    const submitForm = () => {
        setSaving(true);

        const success = (wizard, pdf) => {
            snackbar.setSuccess("Agreement signed");
            setSaving(false);
            onSave(wizard, pdf);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        wizardService.signAnalysisResults(signature, success, failure);
    };

    return <Dialog open={true} classes={useStyles()}>
        <ClosableDialogTitle onClose={onCancel}>
            Product Analysis & Evaluation Results Agreement
        </ClosableDialogTitle>
        <DialogContent className="analysis-results-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <>
                    <Typography paragraph={true}>
                        This is an expressed agreement between <strong>{userName}</strong> ("Disclosing Party"), the inventor,
                        and <strong>{COMPANY_NAME}</strong> and employees, advisers and affiliates of {COMPANY_NAME} ("Receiving Party").
                    </Typography>
                    <Typography paragraph={true}>
                        By clicking on "sign electronically" I agree that I have read and understand the results of the Product Analysis & Evaluation.
                    </Typography>
                    <Table size="small" cellPadding={0} cellSpacing={0}>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <strong>Disclosing Party:</strong>
                                </TableCell>
                                <TableCell>
                                    <SignatureCanvas
                                        ref={setCanvas}
                                        canvasProps={{width: 600, height: 100, className: "signature"}}
                                        onEnd={onStrokeEnd}
                                    />
                                </TableCell>
                                <TableCell className="underlined">
                                    &nbsp;&nbsp;{datetime.today()}&nbsp;&nbsp;
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <strong>Receiving Party:</strong>
                                </TableCell>
                                <TableCell>
                                    {COMPANY_NAME}
                                </TableCell>
                                <TableCell>
                                    &nbsp;
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    &nbsp;
                                </TableCell>
                                <TableCell className="underlined">
                                    &nbsp;&nbsp;{affiliateName}&nbsp;&nbsp;
                                </TableCell>
                                <TableCell>
                                    &nbsp;
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </>}
        </DialogContent>
        {!saving ? <DialogActions style={{
            marginTop: "10px",
        }}>
            <Button color="primary"
                    disabled={!signature}
                    onClick={submitForm}>
                Sign Electronically
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default AnalysisResultsDialog;
