import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Asterisk, ClosableDialogTitle} from "@atttomyx/react-components";
import InventionFields from "../../forms/inventionFields/inventionFields";
import * as wizardService from "../../services/wizards";
import "./inventionDialog.css";

const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: "400px",
    },
}));

const freshData = () => {
    return {
        title: null,
        purpose: null,
        howToUse: null,
        description: null,
        uniqueness: null,
        others: null,
        prototype: null,
        patentDate: null,
        patentType: null,
        valid: false,
    };
};

const InventionDialog = (props) => {
    const { snackbar, onCancel, onSave } = props;
    const [ data, setData ] = useState(freshData());
    const [ saving, setSaving ] = useState(false);

    const submitForm = () => {
        setSaving(true);

        const success = (wizard, pdf) => {
            setSaving(false);
            snackbar.setSuccess("Questionnaire submitted");
            onSave(wizard, pdf);
        };

        const failure = (err) => {
            setSaving(false);
            snackbar.setError(err);
        };

        wizardService.inventionQuestionnaire(data, success, failure);
    }

    return <Dialog open={true} classes={useStyles()}>
        <ClosableDialogTitle onClose={onCancel}>
            Invention Info
        </ClosableDialogTitle>
        <DialogContent className="invention-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <InventionFields
                    data={data}
                    onChange={setData}
                />}
        </DialogContent>
        {!saving ? <DialogActions>
            <Asterisk/>
            <Button color="primary"
                    disabled={!data.valid}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default InventionDialog;
