import React, {useState} from "react";
import {Button, Typography} from "@mui/material";
import YourFilesBlurb from "../../components/yourFilesBlurb/yourFilesBlurb";
import ProductTextDialog from "../../dialogs/productTextDialog/productTextDialog";

const ProductTextStep = (props) => {
    const { snackbar, onSaveWizard, onSaveDocument } = props;
    const [ showDialog, setShowDialog ] = useState(false);

    return <>
        <Typography paragraph={true}>
            Let's complete your product detail information!
        </Typography>
        <Typography paragraph={true}>
            This information should make your product standout! The information you add will
            be included on your one-page sell-sheet, so it’s important to take your time and enter
            quality descriptive information.
        </Typography>
        <YourFilesBlurb/>
        <div className="action">
            <Button
                color="primary"
                onClick={() => setShowDialog(true)}
            >
                Fill Out
            </Button>
        </div>
        {showDialog ?
            <ProductTextDialog
                snackbar={snackbar}
                onCancel={() => setShowDialog(false)}
                onSave={(wizard, pdf) => {
                    setShowDialog(false);
                    onSaveWizard(wizard);
                    onSaveDocument(pdf);
                }}
            /> : null}
    </>
};

export default ProductTextStep;
