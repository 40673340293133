import React, {useState} from "react";
import {Typography} from "@mui/material";
import {Asterisk, FullScreenDialog, ProfilePage} from "@atttomyx/react-components";
import SpinnerButton from "../../components/spinnerButton/spinnerButton";
import UserCustomFields from "../../forms/userCustomFields/userCustomFields";
import * as profileService from "../../services/profile";
import * as wizardService from "../../services/wizards";

const completeProfile = (user) => {
    const settings = user.settings || {};
    const address = settings.address || {};

    return settings.age && settings.gender
        && address.line1 && address.locality && address.region && address.postalCode && address.country;
};

const ProfileStep = (props) => {
    const { snackbar, user, onSaveWizard, onSaveProfile } = props;
    const [ saving, setSaving ] = useState(false);
    const [ showDialog, setShowDialog ] = useState(false);

    const next = (profile) => {
        const success = (wizard) => {
            onSaveWizard(wizard);
            setSaving(false);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        }

        if (completeProfile(profile)) {
            setSaving(true);
            wizardService.markContactInfo(success, failure);
        }
    };

    return <>
        <Typography>
            Please complete your user profile
        </Typography>
        <Typography variant="caption">
            (You may update your contact info at any later time by going to <strong>More -> Profile</strong>)
        </Typography>
        <SpinnerButton
            color="primary"
            label="Edit Profile"
            onClick={() => setShowDialog(true)}
            spinner={saving}
        />
        {showDialog ? <FullScreenDialog
            onCancel={() => {
                setShowDialog(false);
                next(user);
            }}
        >
            <ProfilePage
                snackbar={snackbar}
                user={user}
                onSave={(saved) => {
                    onSaveProfile(saved);
                    setShowDialog(false);
                    next(saved);
                }}
                profileService={profileService}
                showImage={false}
                settingsCards={true}
                settingsFields={UserCustomFields}
                settingsProps={{
                    invention: false,
                }}
            />
            <Asterisk style={{
                float: "left",
                marginTop: "-20px",
            }}/>
        </FullScreenDialog> : null}
    </>
};

export default ProfileStep;
