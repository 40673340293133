import React from "react";
import {TextField, Typography} from "@mui/material";
import {forms, objects, strings, validation} from "@atttomyx/shared-utils";

const SearchFields = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = strings.isNotBlank(modified.firstName)
            && strings.isNotBlank(modified.lastName)
            && strings.isNotBlank(modified.address)
            && strings.isNotBlank(modified.email)
            && strings.isNotBlank(modified.phone1)
            && strings.isNotBlank(modified.title)
            && strings.isNotBlank(modified.purpose)
            && strings.isNotBlank(modified.howToUse)
            && strings.isNotBlank(modified.description)
            && strings.isNotBlank(modified.uniqueness);

        onSync(modified);
    };

    return <div className="search-fields">
        <Typography paragraph={true}>
            <strong>CONTACT INFORMATION</strong>
        </Typography>
        <Typography paragraph={true}>
            <TextField
                label="1a) Inventor First Name"
                required={true}
                type="text"
                value={forms.sanitizeValue(data.firstName)}
                onChange={(event) => onChange("firstName", event.target.value)}
            />
        </Typography>
        <Typography paragraph={true}>
            <TextField
                label="1b) Inventor Last Name"
                required={true}
                type="text"
                value={forms.sanitizeValue(data.lastName)}
                onChange={(event) => onChange("lastName", event.target.value)}
            />
        </Typography>
        <Typography paragraph={true}>
            <TextField
                label="1c) Address"
                required={true}
                type="text"
                value={forms.sanitizeValue(data.address)}
                onChange={(event) => onChange("address", event.target.value)}
            />
        </Typography>
        <Typography paragraph={true}>
            <TextField
                label="1d) Email"
                required={true}
                type="email"
                value={forms.sanitizeValue(data.email)}
                error={!validation.validEmail(data.email)}
                onChange={(event) => onChange("email", event.target.value)}
            />
        </Typography>
        <Typography paragraph={true}>
            <TextField
                label="1e) Phone"
                required={true}
                type="tel"
                value={forms.sanitizeValue(data.phone1)}
                error={!validation.validPhone(data.phone1)}
                onChange={(event) => onChange("phone1", event.target.value)}
            />
        </Typography>
        <Typography paragraph={true}>
            <TextField
                label="1f) Secondary Phone"
                type="tel"
                value={forms.sanitizeValue(data.phone2)}
                error={!validation.validPhone(data.phone2)}
                onChange={(event) => onChange("phone2", event.target.value)}
            />
        </Typography>
        <Typography paragraph={true}>
            <TextField
                label="1g) Additional Inventors"
                type="text"
                value={forms.sanitizeValue(data.inventors)}
                onChange={(event) => onChange("inventors", event.target.value)}
            />
        </Typography>
        <Typography paragraph={true}>
            *All correspondence will be directed to the individual named in 1a & 1b. If there are any changes that
            need to be made please update the information in the space provided in 3f).
        </Typography>
        <Typography paragraph={true}>
            <strong>TITLE</strong>
        </Typography>
        <Typography paragraph={true}>
            <TextField
                label="2) Invention Title"
                required={true}
                type="text"
                value={forms.sanitizeValue(data.title)}
                onChange={(event) => onChange("title", event.target.value)}
            />
        </Typography>
        <Typography paragraph={true}>
            <strong>DISCLOSURE OF YOUR INVENTION</strong>
        </Typography>
        <Typography paragraph={true}>
            <TextField
                label="3a) What is the useful purpose of your invention?"
                required={true}
                type="text"
                value={forms.sanitizeValue(data.purpose)}
                onChange={(event) => onChange("purpose", event.target.value)}
            />
        </Typography>
        <Typography paragraph={true}>
            <TextField
                label="3b) How is your invention used, how does it work better and solve an unsatisfied need, etc.?"
                required={true}
                type="text"
                value={forms.sanitizeValue(data.howToUse)}
                onChange={(event) => onChange("howToUse", event.target.value)}
            />
        </Typography>
        <Typography paragraph={true}>
            <TextField
                label="3c) What is the physical structure of your invention? [Please upload any rough drawings, flow charts in your files]"
                required={true}
                type="text"
                value={forms.sanitizeValue(data.description)}
                onChange={(event) => onChange("description", event.target.value)}
            />
        </Typography>
        <Typography paragraph={true}>
            <TextField
                label="3d) What do you believe to be new about your invention (be very detailed)"
                required={true}
                type="text"
                value={forms.sanitizeValue(data.uniqueness)}
                onChange={(event) => onChange("uniqueness", event.target.value)}
            />
        </Typography>
        <Typography paragraph={true}>
            <TextField
                label="3e) Do you have any knowledge in the field of your invention?"
                type="text"
                value={forms.sanitizeValue(data.knowledge)}
                onChange={(event) => onChange("knowledge", event.target.value)}
            />
        </Typography>
        <Typography paragraph={true}>
            <TextField
                label="3f) Additional Information or changes"
                multiline={true}
                type="text"
                value={forms.sanitizeValue(data.additional)}
                onChange={(event) => onChange("additional", event.target.value)}
            />
        </Typography>
    </div>
}

export default SearchFields;
