import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent} from "@mui/material";
import {Asterisk, ClosableDialogTitle, Info} from "@atttomyx/react-components";
import SharingFields from "../../forms/sharingFields/sharingFields";
import * as documentService from "../../services/documents";
import {forms, users} from "@atttomyx/shared-utils";
import "./sharingDialog.css";

const freshData = () => {
    return {
        email: null,
        valid: false,
    }
};

const SharingDialog = (props) => {
    const { snackbar, onCancel, onShare } = props;
    const [ data, setData ] = useState(freshData());
    const [ saving, setSaving ] = useState(false);

    const submitForm = () => {
        setSaving(true);

        const success = (saved) => {
            snackbar.setSuccess(users.getFullName(saved) + " now has access to your files");
            setSaving(false);
            onShare(saved);
        };

        const failure = (err) => {
            console.log(err);
            snackbar.setWarning("User not found");
            setSaving(false);
        };

        documentService.share(data.email, success, failure);
    }

    return <Dialog
        open={true}
        aria-labelledby="sharing-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Share files
        </ClosableDialogTitle>
        <DialogContent className="sharing-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <>
                    <SharingFields
                        data={data}
                        onChange={setData}
                    />
                    <Info>
                        This will grant access to your confidential files. You may revoke access at any time.
                    </Info>
                </>}
        </DialogContent>
        {!saving ? <DialogActions>
            <Asterisk/>
            <Button color="primary"
                    disabled={!data.valid || !forms.differ(freshData(), data)}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default SharingDialog;
