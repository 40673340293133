import React from "react";
import {Checkbox, FormControlLabel} from "@mui/material";
import {forms, objects} from "@atttomyx/shared-utils";
import "./upsellFields.css";

const UpsellFields = (props) => {
    const {supreme, data, onChange: onSync} = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = true;

        onSync(modified);
    };

    return <div className="upsell-fields">
        <div className="option">
            <FormControlLabel
                labelPlacement="end"
                label="If you do not currently have patent protection, are you interested in speaking with a 3rd-party law firm to discuss patent options?"
                control={<Checkbox
                    color="primary"
                    checked={forms.sanitizeOption(data.patent)}
                    onChange={(event) => onChange("patent", event.target.checked)}
                />}
            />
        </div>
        <div className="option">
            <FormControlLabel
                labelPlacement="end"
                label="Are you interested in a free 30 minute consultation to further discuss ideation and potential improvements to your invention?"
                control={<Checkbox
                    color="primary"
                    checked={forms.sanitizeOption(data.consultation)}
                    onChange={(event) => onChange("consultation", event.target.checked)}
                />}
            />
        </div>
        {!supreme ?
            <div className="option">
                <FormControlLabel
                    labelPlacement="end"
                    label="Are you interested learning more about our marketing services?"
                    control={<Checkbox
                        color="primary"
                        checked={forms.sanitizeOption(data.marketing)}
                        onChange={(event) => onChange("marketing", event.target.checked)}
                    />}
                />
            </div> : null}
    </div>
}

export default UpsellFields;
