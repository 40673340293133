import React, {useEffect, useState} from "react";
import {CircularProgress, Typography} from "@mui/material";
import {useFiltered} from "@atttomyx/shared-hooks";
import {FiltersAccordion, FloatingAddButton, UserField} from "@atttomyx/react-components";
import DocumentFilters from "../../forms/documentFilters/documentFilters";
import DocumentsCard from "../../cards/documentsCard/documentsCard";
import DocumentDialog from "../../dialogs/documentDialog/documentDialog";
import SharingCard from "../../cards/sharingCard/sharingCard";
import {arrays, sorting} from "@atttomyx/shared-utils";
import {sanitizeTag} from "../../utils/tags";
import {TAGS} from "../../constants";

const DocumentsPage = (props) => {
    const { snackbar, user, users, documents, documentCache, filters, sources, targets, onUpload, onShare, onRevoke } = props;
    const [ userId, setUserId ] = useState(null);
    const [ files, setFiles ] = useState([]);
    const [ showFilters, setShowFilters ] = useState(false);
    const [ showUpload, setShowUpload ] = useState(false);
    const [ availableUsers, setAvailableUsers ] = useState([]);
    const [ filesByTag, setFilesByTag ] = useState({});
    const filtered = useFiltered(files, filters, sorting.sortByUpdatedDesc);

    const own = !user.roles.admin && (!userId || userId === user.id);

    useEffect(() => {
        if (user.roles.admin) {
            const nonAdmins = users.filter(user => !user.roles.admin);

            setAvailableUsers(nonAdmins);

        } else {
            setAvailableUsers(sources);
            setUserId(user.id);
        }
    }, [user, users, sources]);

    useEffect(() => {
        if (own) {
            setFiles(documents);

        } else if (userId) {
            documentCache.get(userId, setFiles);
        }
    }, [user, documents, documentCache, userId]);

    useEffect(() => {
        const grouped = {};
        const sorted = {};

        filtered.forEach(file => file.tags.forEach(tag => {
            const sanitized = sanitizeTag(tag);
            const group = grouped[sanitized] || [];

            arrays.addTo(group, file);
            grouped[sanitized] = group;
        }));

        Object.keys(TAGS).forEach(tag => {
            const files = grouped[tag];

            if (files) {
                sorted[tag] = files;
            }
        });

        setFilesByTag(sorted);
    }, [filtered]);

    return <div className="documents-page">
        {documentCache.loading ?
            <CircularProgress size="40px"/> :
            <>
                {user.roles.admin || availableUsers.length > 0 ?
                    <div className="field">
                        <UserField
                            label="Whose files?"
                            users={availableUsers}
                            value={userId}
                            onChange={setUserId}
                            required={true}
                            options={!user.roles.admin ? {
                                [user.id]: "Mine",
                            } : undefined}
                        />
                    </div> : null}
                <div className="field">
                    <FiltersAccordion
                        filters={filters}
                        form={DocumentFilters}
                        open={showFilters}
                        onOpen={() => setShowFilters(true)}
                        onClose={() => setShowFilters(false)}
                    />
                </div>
                <div className="cards">
                    {Object.keys(filesByTag).length > 0 ? Object.entries(filesByTag).map(([tag, files]) =>
                            <DocumentsCard
                                key={tag}
                                snackbar={snackbar}
                                user={user}
                                userId={userId}
                                tag={tag}
                                documents={files}
                            />) : !own ?
                        <Typography>
                            No files
                        </Typography> : null}
                    {own ?
                        <SharingCard
                            snackbar={snackbar}
                            targets={targets}
                            onShare={onShare}
                            onRevoke={onRevoke}
                        /> : null}
                </div>
                <FloatingAddButton title="Upload file" position="higher"
                                   onClick={() => setShowUpload(true)}
                                   disabled={!userId}
                />
                {showUpload ? <DocumentDialog
                    snackbar={snackbar}
                    user={user}
                    userId={userId}
                    onCancel={() => setShowUpload(false)}
                    onUpload={(saved) => {
                        setShowUpload(false);

                        if (own) {
                            onUpload(saved);

                        } else if (userId) {
                            documentCache.onEntitySaved(userId, saved);
                        }
                    }}
                /> : null}
            </>}
    </div>
}

export default DocumentsPage;
