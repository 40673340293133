import React from "react";
import {Checkbox, FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";
import {forms, money, objects} from "@atttomyx/shared-utils";
import BundleCard from "../../cards/bundleCard/bundleCard";
import {
    BUNDLE_BRONZE,
    BUNDLE_GOLD,
    BUNDLE_SILVER,
    MARKETING_PATENT_ILLUSTRATIONS,
    MARKETING_PRODUCT_IMAGES,
    PRICES
} from "../../constants";
import "./marketingFields.css";

const MarketingFields = (props) => {
    const {data, onChange: onSync} = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = (modified.images || modified.patent || modified.assistance)
            && (!modified.assistance || modified.bundle);

        onSync(modified);
    };

    return <div className="marketing-fields">
        <div className="option">
            <FormControlLabel
                labelPlacement="end"
                label={money.render(PRICES[MARKETING_PRODUCT_IMAGES])
                    + " - New Product Concept & Development Renditions"}
                control={<Checkbox
                    color="primary"
                    checked={forms.sanitizeOption(data.images)}
                    onChange={(event) => onChange("images", event.target.checked)}
                />}
            />
            <Typography variant="caption" component="p">
                In order to market your invention, you should have the best images possible to present to prospective
                manufacturers. We offer 5 high quality virtual images that will show how your idea will look as a real product.
                Our in-house professional design team will work directly with you to design your product and bring it life.
            </Typography>
        </div>
        <div className="option">
            <FormControlLabel
                labelPlacement="end"
                label={money.render(PRICES[MARKETING_PATENT_ILLUSTRATIONS])
                    + " - Patent Illustrations"}
                control={<Checkbox
                    color="primary"
                    checked={forms.sanitizeOption(data.patent)}
                    onChange={(event) => onChange("patent", event.target.checked)}
                />}
            />
            <Typography variant="caption" component="p">
                Our professional patent illustrations provide you and your Patent Attorney or Patent Agent with a great head start
                on your patent application. We can provide Provisional and/or Non-Provisional USPTO compliant drawings to
                accelerate your process timelines and keep your expenses well within budget.
            </Typography>
        </div>
        <div className="option">
            <FormControlLabel
                labelPlacement="end"
                label="Marketing & Licensing Assistance"
                control={<Checkbox
                    color="primary"
                    checked={forms.sanitizeOption(data.assistance)}
                    onChange={(event) => onChange("assistance", event.target.checked)}
                />}
            />
            <Typography variant="caption" component="p">
                Companies are always looking for new products. Whether you feel comfortable self-marketing your product on
                your own, or if you prefer a marketing firm to contact companies on your behalf, we can assist with both! We will
                provide you with all the marketing materials needed, including a professional sell-sheet that you can send directly
                to companies, a blank non-disclosure form that can be sent to prospective companies that you contact, and a
                copy of a licensing agreement. Additionally, our marketing firm can assist with full company contact, licensing
                and negotiations. We will always provide you with company information and any feedback they may provide to
                us about your product.
            </Typography>
        </div>
        {data.assistance ?
            <div className="field">
                <RadioGroup row={false} aria-labelledby="bundle"
                            onChange={(event) => onChange("bundle", event.target.value)}>
                    <FormControlLabel
                        value={BUNDLE_GOLD}
                        label={<BundleCard type={BUNDLE_GOLD}/>}
                        control={<Radio checked={data.bundle === BUNDLE_GOLD} color="primary"/>}
                    />
                    <FormControlLabel
                        value={BUNDLE_SILVER}
                        label={<BundleCard type={BUNDLE_SILVER}/>}
                        control={<Radio checked={data.bundle === BUNDLE_SILVER} color="primary"/>}
                    />
                    <FormControlLabel
                        value={BUNDLE_BRONZE}
                        label={<BundleCard type={BUNDLE_BRONZE}/>}
                        control={<Radio checked={data.bundle === BUNDLE_BRONZE} color="primary"/>}
                    />
                </RadioGroup>
            </div> : null}
    </div>
}

export default MarketingFields;
