import React from "react";
import {FormLabel, Table, TableBody, TableCell, TableRow, TextField} from "@mui/material";
import {forms, objects} from "@atttomyx/shared-utils";
import "./moneyFields.css";

const MoneyFields = (props) => {
    const {label, data, onChange: onSync} = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = modified.dollars >= 0 && modified.cents >= 0 && modified.cents < 100;

        onSync(modified);
    }

    return <div className="money-fields">
        <FormLabel>{label}</FormLabel>
        <Table size="small" cellPadding={0} cellSpacing={0}>
            <TableBody>
                <TableRow key={document.id}>
                    <TableCell>
                        <TextField label="Dollars" required={true}
                                   type="number"
                                   value={forms.sanitizeValue(data.dollars)}
                                   onChange={(event) => onChange("dollars", event.target.value)}
                                   inputProps={{
                                       min: 0,
                                   }}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField label="Cents" required={true}
                                   type="number"
                                   value={forms.sanitizeValue(data.cents)}
                                   onChange={(event) => onChange("cents", event.target.value)}
                                   inputProps={{
                                       min: 0,
                                       max: 99,
                                   }}
                        />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </div>
}

export default MoneyFields;
