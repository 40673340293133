import React from 'react';
import {Card, CardActions, CardContent, Divider, Typography} from "@mui/material";
import {UserAvatar} from "@atttomyx/react-components";
import {datetime} from "@atttomyx/shared-utils";
import {UNANSWERED} from "../../constants";
import "./helpCard.css";

const HelpCard = (props) => {
    const { user, help, usersById, userId, children } = props;

    const renderUser = (userId) => {
        const user = usersById[userId] || {};

        return <UserAvatar user={user} mode="right"/>
    };

    return <Card className="help-card" raised={true}>
        <CardContent>
            {user.roles.admin && userId === UNANSWERED ? <>
                {renderUser(help.userId)}
                <Divider/>
            </> : null}
            <Typography variant="body1">
                {help.question}
            </Typography>
            <Typography variant="caption" className="timestamp">
                {datetime.getPrettyDateAndTime(help.created)}
            </Typography>
            {help.answer ? <>
                <Divider/>
                <Typography variant="body1">
                    {help.answer}
                </Typography>
                <Typography variant="caption" className="timestamp">
                    {datetime.getPrettyDateAndTime(help.updated)}
                </Typography>
            </> : null}
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
    </Card>
}

export default HelpCard;
