import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Asterisk, ClosableDialogTitle} from "@atttomyx/react-components";
import HelpFields from "../../forms/helpFields/helpFields";
import * as helpService from "../../services/helps";
import {forms, strings} from "@atttomyx/shared-utils";
import "./helpDialog.css";

const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: "400px",
    },
}));

const toData = (user, help) => {
    const text = user.roles.admin ? help.answer : help.question;

    return {
        text: text,
        valid: strings.isNotBlank(text),
    }
};

const HelpDialog = (props) => {
    const { snackbar, user, help, onCancel, onAsk, onAnswer } = props;
    const [ data, setData ] = useState(toData(user, help));
    const [ saving, setSaving ] = useState(false);

    const noun = user.roles.admin ? "Answer" : "Question";
    const verb = user.roles.admin ? "Answer" : "Ask";

    const submitForm = () => {
        setSaving(true);

        const onSave = user.roles.admin ? onAnswer : onAsk;

        const success = (saved) => {
            snackbar.setSuccess(noun + " saved");
            setSaving(false);
            onSave(saved);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        if (user.roles.admin) {
            helpService.answerQuestion(help.id, data.text, success, failure);

        } else {
            helpService.askQuestion(data.text, success, failure);
        }
    }

    return <Dialog open={true} classes={useStyles()}>
        <ClosableDialogTitle onClose={onCancel}>
            {verb} question
        </ClosableDialogTitle>
        <DialogContent className="help-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <>
                    {user.roles.admin ?
                        <Typography>
                            {help.question}
                        </Typography> : null}
                    <HelpFields
                        data={data}
                        onChange={setData}
                        textLabel={noun}
                    />
                </>}
        </DialogContent>
        {!saving ? <DialogActions>
            <Asterisk/>
            <Button color="primary"
                    disabled={!data.valid || !forms.differ(toData(user, help), data)}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default HelpDialog;
