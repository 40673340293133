import React from "react";
import {Checkbox, FormControlLabel, TextField} from "@mui/material";
import {AddressForm} from "@atttomyx/react-components";
import GenderField from "../genderField/genderField";
import {forms, objects} from "@atttomyx/shared-utils";

const UserCustomFields = (props) => {
    const { data, onChange : onSync, settingsProps } = props;
    const { notes, testMode } = settingsProps;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;

        const addressValid = modified.address && objects.defaultIfNullOrUndefined(modified.address.valid, true);

        modified.valid = modified.age && addressValid;

        onSync(modified);
    };

    return <>
        <div className="card">
            <div className="field">
                <TextField label="Age" required={true}
                           type="number"
                           value={forms.sanitizeValue(data.age)}
                           onChange={(event) => onChange("age", event.target.value)}
                           inputProps={{
                               min: 0,
                           }}
                />
            </div>
            <div className="field">
                <GenderField
                    value={data.gender}
                    onChange={(gender) => onChange("gender", gender)}
                />
            </div>
            {notes ? <div className="field">
                <TextField label="Notes"
                           type="text" multiline={true}
                           value={forms.sanitizeValue(data.notes)}
                           onChange={(event) => onChange("notes", event.target.value)}
                />
            </div> : null}
            {testMode ? <div className="option">
                <FormControlLabel labelPlacement="end"
                                  label="Test mode"
                                  control={<Checkbox color="primary"
                                                     checked={forms.sanitizeOption(data.test)}
                                                     onChange={(event) => onChange("test", event.target.checked)}
                                  />}
                />
            </div> : null}
        </div>
        <div className="card">
            <AddressForm
                data={data.address || {}}
                onChange={address => onChange("address", address)}
                required={true}
            />
        </div>
    </>
}

export default UserCustomFields;
