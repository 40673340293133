import React from 'react';
import {Card, CardContent, Typography} from "@mui/material";
import {MilitaryTech as MedalIcon} from "@mui/icons-material";
import {money} from "@atttomyx/shared-utils";
import {BUNDLE_BRONZE, BUNDLE_GOLD, BUNDLE_SILVER, PRICES} from "../../constants";
import "./bundleCard.css";

const BundleCard = (props) => {
    const { type } = props;

    return <Card className={"bundle-card " + type} raised={true}>
        <CardContent>
            <MedalIcon className={type}/>
            {type === BUNDLE_BRONZE ?
                <>
                    <Typography variant="h6">
                        Bronze Package
                    </Typography>
                    <ul>
                        <li>
                            Professional Sell-sheet
                        </li>
                        <li>
                            Product promotion email
                        </li>
                        <li>
                            Product promotion letter
                        </li>
                        <li>
                            We will provide you with 5 companies to contact
                        </li>
                    </ul>
                </> : type === BUNDLE_SILVER ?
                <>
                    <Typography variant="h6">
                        Silver Package
                    </Typography>
                    <ul>
                        <li>
                            Professional Sell-sheet
                        </li>
                        <li>
                            Proactive Marketing efforts to 10 companies within your product category
                        </li>
                        <li>
                            Proactive Marketing efforts to 10 companies within your product category
                        </li>
                        <li>
                            A valuable trade list of manufacturers for you, the inventor, to self-market to in parallel to our efforts
                        </li>
                    </ul>
                </> : type === BUNDLE_GOLD ?
                <>
                    <Typography variant="h6">
                        Gold Package
                    </Typography>
                    <ul>
                        <li>
                            Professional Sell-sheet
                        </li>
                        <li>
                            Proactive Marketing efforts to 25 companies within your product category
                        </li>
                        <li>
                            Marketing updates provided
                        </li>
                        <li>
                            A valuable trade list of manufacturers for you, the inventor, to self-market to in parallel to our efforts
                        </li>
                    </ul>
                </> : null}
            <strong>{money.render(PRICES[type])}</strong>
        </CardContent>
    </Card>
}

export default BundleCard;
