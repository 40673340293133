import React, {useState} from 'react';
import {
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import {AddCircle as ShareIcon, Delete as RevokeIcon} from "@mui/icons-material";
import {UserAvatar} from "@atttomyx/react-components";
import SharingDialog from "../../dialogs/sharingDialog/sharingDialog";
import * as documentService from "../../services/documents";
import {users} from "@atttomyx/shared-utils";
import {confirm} from "@atttomyx/react-utils";
import "./sharingCard.css";

const SharingCard = (props) => {
    const { snackbar, targets, onShare, onRevoke } = props;
    const [ showShare, setShowShare ] = useState(false);
    const [ revoking, setRevoking ] = useState(false);

    const revoke = (target) => {
        setRevoking(target.id);

        const success = (userId) => {
            setRevoking(false);
            onRevoke(userId);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setRevoking(false);
        };

        documentService.revoke(target.id, success, failure);
    };

    return <Card className="sharing-card" raised={true}>
        <CardContent>
            <Typography variant="h4" paragraph={true}>
                File Sharing
            </Typography>
            {targets.length > 0 ?
                <Table size="small" cellPadding={0} cellSpacing={0} className="striped">
                    <TableBody>
                        {targets.map(target =>
                            <TableRow key={target.id}>
                                <TableCell>
                                    <UserAvatar user={target} mode="right" size="small"/>
                                </TableCell>
                                <TableCell>
                                    {revoking === target.id ?
                                        <CircularProgress size={14} color="secondary"/> :
                                        <IconButton color="secondary" title="Revoke access"
                                                    onClick={() =>
                                                        confirm.confirm(`Revoke ${users.getFullName(target)}'s access to your files?`,
                                                            () => revoke(target))}>
                                            <RevokeIcon/>
                                        </IconButton>}
                                </TableCell>
                            </TableRow>)}
                    </TableBody>
                </Table> :
                <Typography>
                    Not sharing
                </Typography>}
        </CardContent>
        <CardActions disableSpacing={true}>
            <IconButton color="primary" title="Share files"
                        onClick={() => setShowShare(true)}>
                <ShareIcon/>
            </IconButton>
        </CardActions>
        {showShare ? <SharingDialog
            snackbar={snackbar}
            onCancel={() => setShowShare(false)}
            onShare={(saved) => {
                setShowShare(false);
                onShare(saved);
            }}
        /> : null}
    </Card>
}

export default SharingCard;
