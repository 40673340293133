import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {CircularProgress, IconButton, Typography} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon, Task as GotoIcon} from "@mui/icons-material";
import {useFiltered} from "@atttomyx/shared-hooks";
import {Cards, ConfirmDeleteDialog, FiltersAccordion} from "@atttomyx/react-components";
import TaskCard from "../../cards/taskCard/taskCard";
import TaskDialog from "../../dialogs/taskDialog/taskDialog";
import TaskFilters from "../../forms/taskFilters/taskFilters";
import * as taskService from "../../services/tasks";
import {sortByDue} from "../../utils/tasks";
import {strings} from "@atttomyx/shared-utils";
import {confirm, router} from "@atttomyx/react-utils";
import {PAGE_TASK} from "../../constants";

const TasksPage = (props) => {
    const { snackbar, dimensions, history, users, userRenderer, tasks, filters, onSave, onDelete } = props;
    const [ showFilters, setShowFilters ] = useState(false);
    const [ showEdit, setShowEdit ] = useState(false);
    const [ showDelete, setShowDelete ] = useState(false);
    const [ deleting, setDeleting ] = useState(false);
    const [ task, setTask ] = useState(null);
    const [ availableAdmins, setAvailableAdmins ] = useState([]);
    const [ availableUsers, setAvailableUsers ] = useState([]);
    const filtered = useFiltered(tasks, filters, sortByDue);

    useEffect(() => {
        const admins = users.filter(user => user.roles.admin);
        const nonAdmins = users.filter(user => !user.roles.admin);

        setAvailableAdmins(admins);
        setAvailableUsers(nonAdmins);
    }, [users]);

    const deleteTask = (taskId) => {
        setDeleting(true);

        const success = (taskId) => {
            setShowDelete(false);
            setTask(null);

            snackbar.setSuccess("Task deleted");
            onDelete(taskId);
        };

        const failure = (err) => {
            setDeleting(false);

            snackbar.setError("Unable to delete task");
        };

        taskService.deleteTask(taskId, success, failure);
    };

    return <div className="tasks-page">
        {deleting ?
            <CircularProgress size="80px" color="secondary"/> :
            <>
                <Typography variant="h5" paragraph={true}>
                    Tasks
                </Typography>
                <div className="field">
                    <FiltersAccordion
                        filters={filters}
                        form={TaskFilters}
                        formProps={{
                            admins: availableAdmins,
                            users: availableUsers,
                        }}
                        open={showFilters}
                        onOpen={() => setShowFilters(true)}
                        onClose={() => setShowFilters(false)}
                    />
                </div>
                <Cards
                    items={filtered}
                    paging={filters.paging}
                    dimensions={dimensions}
                    renderer={(task) =>
                        <TaskCard key={task.id} task={task} filters={filters} userRenderer={userRenderer}>
                            <IconButton color="secondary" title="Delete" className="delete"
                                        onClick={() => {
                                            if (task.completed) {
                                                confirm.confirmDelete(task.title, () => deleteTask(task.id));

                                            } else {
                                                setTask(task);
                                                setShowDelete(true);
                                            }
                                        }}>
                                <DeleteIcon/>
                            </IconButton>
                            <IconButton color="primary" title="Do"
                                        disabled={strings.isNotBlank(task.completed)}
                                        onClick={() => router.redirectTo(history, PAGE_TASK + "/" + task.id)}>
                                <GotoIcon/>
                            </IconButton>
                            <IconButton color="primary" title="Edit"
                                        disabled={strings.isNotBlank(task.completed)}
                                        onClick={() => {
                                            setTask(task);
                                            setShowEdit(true);
                                        }}>
                                <EditIcon/>
                            </IconButton>
                        </TaskCard>}
                />
                {task && showEdit ? <TaskDialog
                    snackbar={snackbar}
                    admins={availableAdmins}
                    task={task}
                    onCancel={() => setTask(null)}
                    onSave={(saved) => {
                        setTask(null);
                        onSave(saved);
                    }}
                /> : null}
                {task && showDelete ? <ConfirmDeleteDialog
                    snackbar={snackbar}
                    title={task.title}
                    onCancel={() => {
                        setShowDelete(false);
                        setTask(null);
                    }}
                    onDelete={(taskId) => {
                        setShowDelete(false);
                        setTask(null);
                        onDelete(taskId);
                    }}
                    deleter={(success, failure) => taskService.deleteTask(task.id, success, failure)}
                /> : null}
            </>}
    </div>
}

export default withRouter(TasksPage);
