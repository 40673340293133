import React, {useState} from "react";
import {Typography} from "@mui/material";
import SpinnerButton from "../../components/spinnerButton/spinnerButton";
import DocumentCard from "../../cards/documentCard/documentCard";
import * as wizardService from "../../services/wizards";
import {arrays} from "@atttomyx/shared-utils";
import {TAG_MARKETING} from "../../constants";
import "./productImagesStep.css";

const addToIfNotNull = (list, document) => {
    if (document) {
        arrays.addTo(list, document.id);
    }
};

const ProductImagesStep = (props) => {
    const { snackbar, user, onSaveWizard, onSaveDocument } = props;
    const [ image1, setImage1 ] = useState(null);
    const [ image2, setImage2 ] = useState(null);
    const [ image3, setImage3 ] = useState(null);
    const [ image4, setImage4 ] = useState(null);
    const [ image5, setImage5 ] = useState(null);
    const [ saving, setSaving ] = useState(false);

    const saveImages = () => {
        setSaving(true);

        const success = (wizard) => {
            snackbar.setSuccess("Image choices saved");
            onSaveWizard(wizard);
            setSaving(false);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        }

        const documentIds = [];
        addToIfNotNull(documentIds, image1);
        addToIfNotNull(documentIds, image2);
        addToIfNotNull(documentIds, image3);
        addToIfNotNull(documentIds, image4);
        addToIfNotNull(documentIds, image5);

        wizardService.productImages(documentIds, success, failure);
    };

    const renderCard = (num, value, setter) => {
        return <DocumentCard
            snackbar={snackbar}
            user={user}
            userId={user.id}
            num={num}
            document={value}
            tag={TAG_MARKETING}
            onSaveDocument={(document) => {
                setter(document);
                onSaveDocument(document);
            }}
        />
    };

    return <div className="product-images-step">
        <Typography paragraph={true}>
            Please upload up to 5 quality pictures or images of your invention.
        </Typography>
        <Typography paragraph={true}>
            These images are what prospective companies will see and use to determine the next steps and possible
            licensing opportunities. We recommend the following:
        </Typography>
        <ul className="bullets">
            <li>
                <Typography variant="caption">
                    Keep background colors neutral and clutter-free.
                </Typography>
            </li>
            <li>
                <Typography variant="caption">
                    All images should be of a high quality. Pencil drawings or grid drawings do not show up well, nor do blurry or unclear images.
                </Typography>
            </li>
            <li>
                <Typography variant="caption">
                    Images should be horizontal, not vertical.
                </Typography>
            </li>
            <li>
                <Typography variant="caption">
                    JPEG/Digital camera format preferred.
                </Typography>
            </li>
            <li>
                <Typography variant="caption">
                    DO NOT write on the pictures.
                </Typography>
            </li>
        </ul>
        <div className="cards">
            {renderCard(1, image1, setImage1)}
            {renderCard(2, image2, setImage2)}
            {renderCard(3, image3, setImage3)}
            {renderCard(4, image4, setImage4)}
            {renderCard(5, image5, setImage5)}
        </div>
        <SpinnerButton
            color="primary"
            label="Save Choices"
            onClick={saveImages}
            disabled={!image1 && !image2 && !image3 && !image4 && !image5}
            spinner={saving}
        />
    </div>
};

export default ProductImagesStep;
