import React from "react";
import {Button, Typography} from "@mui/material";
import MarketingFields from "../../forms/marketingFields/marketingFields";
import {COMPANY_NAME} from "../../constants";

const MarketingStoreStep = (props) => {
    const { marketing, onChange, onCheckout } = props;

    return <>
        <Typography paragraph={true}>
            {COMPANY_NAME} is committed to providing our customers with the best quality
            consulting services for new product innovation with end-to-end solutions. Please choose from
            the following services so we can bring your invention to life!
        </Typography>
        <MarketingFields
            data={marketing}
            onChange={onChange}
        />
        <div className="action">
            <Button
                color="primary"
                onClick={onCheckout}
                disabled={!marketing.valid}
            >
                Checkout
            </Button>
        </div>
    </>
};

export default MarketingStoreStep;
