import React from "react";
import {Typography} from "@mui/material";
import YourFilesLink from "../yourFilesLink/yourFilesLink";

const YourFilesBlurb = () => {
    return <Typography variant="caption">
        (A copy will be uploaded to <YourFilesLink/>)
    </Typography>
};

export default YourFilesBlurb;
