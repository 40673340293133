import React, {useEffect, useState} from "react";
import {CircularProgress, IconButton} from "@mui/material";
import {Reply as AnswerIcon} from "@mui/icons-material";
import {useFiltered} from "@atttomyx/shared-hooks";
import {Cards, FiltersAccordion, FloatingAddButton, UserField} from "@atttomyx/react-components";
import HelpFilters from "../../forms/helpFilters/helpFilters";
import HelpCard from "../../cards/helpCard/helpCard";
import HelpDialog from "../../dialogs/helpDialog/helpDialog";
import {arrays, sorting} from "@atttomyx/shared-utils";
import {UNANSWERED} from "../../constants";

const HelpsPage = (props) => {
    const { snackbar, dimensions, user, users, helps, helpCache, filters, onAsk, onAnswer } = props;
    const [ userId, setUserId ] = useState(user.roles.admin ? UNANSWERED : null);
    const [ showFilters, setShowFilters ] = useState(false);
    const [ help, setHelp ] = useState(null);
    const [ usersById, setUsersById ] = useState({});
    const [ availableUsers, setAvailableUsers ] = useState([]);
    const [ questions, setQuestions ] = useState([]);
    const filtered = useFiltered(questions, filters, sorting.sortByCreatedDesc);

    const refresh = (fresh) => {
        if (userId && userId !== UNANSWERED) {
            helpCache.get(userId, setQuestions, fresh);

        } else {
            setQuestions(helps);

            if (fresh) {
                helpCache.clear();
            }
        }
    };

    useEffect(() => refresh(true), [helps]);
    useEffect(() => refresh(false), [userId]);

    useEffect(() => {
        const nonAdmins = users.filter(user => !user.roles.admin);

        setUsersById(arrays.getIdToEntity(nonAdmins));
        setAvailableUsers(nonAdmins);
    }, [users]);

    useEffect(() => {
        if (userId && userId !== UNANSWERED) {
            helpCache.get(userId, setQuestions);

        } else {
            setQuestions(helps);
        }
    }, [userId, helps]);

    return <div className="helps-page">
        {helpCache.loading ?
            <CircularProgress size="40px"/> :
            <>
                {user.roles.admin || availableUsers.length > 1 ?
                    <div className="field">
                        <UserField
                            label="Whose questions?"
                            users={availableUsers}
                            value={userId}
                            onChange={setUserId}
                            required={true}
                            options={{
                                [UNANSWERED]: "All unanswered",
                            }}
                        />
                    </div> : null}
                <div className="field">
                    <FiltersAccordion
                        filters={filters}
                        form={HelpFilters}
                        formProps={{
                            user: user,
                            users: availableUsers,
                            userId: userId,
                        }}
                        open={showFilters}
                        onOpen={() => setShowFilters(true)}
                        onClose={() => setShowFilters(false)}
                    />
                </div>
                <Cards
                    items={filtered}
                    paging={filters.paging}
                    dimensions={dimensions}
                    renderer={(help) =>
                        <HelpCard key={help.id} help={help} user={user} usersById={usersById} userId={userId}>
                            {user.roles.admin && !help.answer ?
                                <IconButton color="primary" title="Answer"
                                            onClick={() => setHelp(help)}>
                                    <AnswerIcon/>
                                </IconButton> : null}
                        </HelpCard>}
                />
                {!user.roles.admin ?
                    <FloatingAddButton title="Ask question" position="higher"
                                       onClick={() => setHelp({})}
                    /> : null}
                {help ? <HelpDialog
                    snackbar={snackbar}
                    user={user}
                    help={help}
                    onCancel={() => setHelp(null)}
                    onAsk={(saved) => {
                        setHelp(null);
                        onAsk(saved);
                    }}
                    onAnswer={(saved) => {
                        setHelp(null);
                        onAnswer(saved.id);
                    }}
                /> : null}
            </>}
    </div>
}

export default HelpsPage;
