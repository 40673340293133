import React, {useState} from "react";
import {Button, Typography} from "@mui/material";
import YourFilesLink from "../../components/yourFilesLink/yourFilesLink";
import QuestionsBlurb from "../../components/questionsBlurb/questionsBlurb";
import YourFilesBlurb from "../../components/yourFilesBlurb/yourFilesBlurb";
import SearchResultsDialog from "../../dialogs/searchResultsDialog/searchResultsDialog";

const SearchResultsStep = (props) => {
    const { snackbar, user, onSaveWizard, onSaveDocument } = props;
    const [ showDialog, setShowDialog ] = useState(false);

    return <>
        <Typography variant="h6" paragraph={true}>
            Thank you!
        </Typography>
        <Typography>
            Your search results will be uploaded to <YourFilesLink/> soon.
        </Typography>
        <QuestionsBlurb/>
        <Typography>
            After reviewing the results, please complete the agreement.
        </Typography>
        <YourFilesBlurb/>
        <div className="action">
            <Button
                color="primary"
                onClick={() => setShowDialog(true)}
            >
                Sign Agreement
            </Button>
        </div>
        {showDialog ?
            <SearchResultsDialog
                snackbar={snackbar}
                user={user}
                onCancel={() => setShowDialog(false)}
                onSave={(wizard, pdf) => {
                    setShowDialog(false);
                    onSaveWizard(wizard);
                    onSaveDocument(pdf);
                }}
            /> : null}
    </>
};

export default SearchResultsStep;
