import React from 'react';
import {Card, CardContent, Divider, Typography} from "@mui/material";
import "./searchAgreementCard.css";

const SearchAgreementCard = (props) => {
    const { task } = props;

    const metadata = task.metadata || {};
    const agreement = metadata.searchAgreement || {};

    const renderQuestionAnswer = (question, answer) => {
        return <>
            <Typography variant="caption" className="question">
                {question}
            </Typography>
            <Typography variant="caption" className="answer" paragraph={true}>
                {answer}
            </Typography>
        </>
    };

    return <Card className="search-agreement-card" raised={true}>
        <CardContent>
            <Typography variant="h5" paragraph={true}>
                Agreement
            </Typography>
            <Divider/>
            {renderQuestionAnswer("1a) Inventor First Name:", agreement.firstName)}
            {renderQuestionAnswer("1b) Inventor Last Name:", agreement.lastName)}
            {renderQuestionAnswer("1c) Address:", agreement.address)}
            {renderQuestionAnswer("1d) Email:", agreement.email)}
            {renderQuestionAnswer("1e) Phone:", agreement.phone1)}
            {renderQuestionAnswer("1f) Secondary Phone:", agreement.phone2)}
            {renderQuestionAnswer("1g) Additional Inventors:", agreement.inventors)}
            {renderQuestionAnswer("2) Invention Title:", agreement.title)}
            {renderQuestionAnswer("3a) What is the useful purpose of your invention?", agreement.purpose)}
            {renderQuestionAnswer("3b) How is your invention used?", agreement.howToUse)}
            {renderQuestionAnswer("3c) What is the physical structure of your invention?", agreement.description)}
            {renderQuestionAnswer("3d) What do you believe to be new about your invention", agreement.uniqueness)}
            {renderQuestionAnswer("3e) Do you have any knowledge in the field of your invention?", agreement.knowledge)}
            {renderQuestionAnswer("3f) Additional Information or changes", agreement.additional)}
        </CardContent>
    </Card>
}

export default SearchAgreementCard;
