import React, {useState} from "react";
import {Typography} from "@mui/material";
import SpinnerButton from "../../components/spinnerButton/spinnerButton";
import * as wizardService from "../../services/wizards";

const WelcomeStep = (props) => {
    const { snackbar, onSaveWizard } = props;
    const [ saving, setSaving ] = useState(false);

    const next = () => {
        setSaving(true);

        const success = (wizard) => {
            onSaveWizard(wizard);
            setSaving(false);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        }

        wizardService.markWelcome(success, failure);
    };

    return <>
        <Typography variant="h6" paragraph={true}>
            WELCOME TO YOUR CUSTOMER PORTAL!
        </Typography>
        <Typography>
            We appreciate your business and look forward to assisting with all your product innovation needs.
        </Typography>
        <SpinnerButton
            color="primary"
            label="Start"
            onClick={next}
            spinner={saving}
        />
    </>
};

export default WelcomeStep;
