import React, {useEffect, useState} from 'react';
import {encode} from 'base64-arraybuffer';
import {Button, Card, CircularProgress, Typography} from "@mui/material";
import DocumentDialog from "../../dialogs/documentDialog/documentDialog";
import * as documentService from "../../services/documents";
import "./documentCard.css";

const DocumentCard = (props) => {
    const { snackbar, user, userId, num, document, tag, onSaveDocument } = props;
    const [ downloading, setDownloading ] = useState(false);
    const [ showUpload, setShowUpload ] = useState(false);
    const [ base64, setBase64 ] = useState(null);

    const download = (document) => {
        setDownloading(true);

        const success = (bytes) => {
            setDownloading(false);
            setBase64(encode(bytes));
        };

        const failure = (err) => {
            snackbar.setError(err);
            setDownloading(false);
        };

        documentService.downloadDocument(userId, document.fileName, success, failure);
    };

    useEffect(() => {
        if (document) {
            download(document);
        }
    }, [document]);

    const renderButton = (label) => {
        return <div className="action">
            <Button
                color="primary"
                variant="text"
                onClick={() => setShowUpload(true)}
            >{label}</Button>
        </div>
    };

    return <Card className="document-card" raised={true}>
        {downloading ?
            <CircularProgress size="28px" color="primary" className="loading"/> : base64 ?
            <>
                <img
                    alt={document.fileName}
                    src={`data:${document.mimeType};base64,` + base64}
                    className="preview"
                />
                {renderButton("Edit Image")}
            </> :
            <>
                <Typography variant="h2" paragraph={true} className="num">
                    {num}
                </Typography>
                {renderButton("Add Image")}
            </>}
        {showUpload ?
            <DocumentDialog
                snackbar={snackbar}
                user={user}
                userId={userId}
                tag={tag}
                onCancel={() => setShowUpload(false)}
                onUpload={(saved) => {
                    setShowUpload(false);
                    onSaveDocument(saved);
                }}
            /> : null}
    </Card>
}

export default DocumentCard;
