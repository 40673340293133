import {notifications} from "@atttomyx/shared-constants";

export const APP_NAME = "Innoventive";
export const APP_TAG_LINE = "Helping inventors";

export const COMPANY_NAME = "Innovation Development Team";

export const EMAIL_SUPPORT = "support@innovationdevelopmentteam.com";
export const EMAIL_INFO = "info@innovationdevelopmentteam.com";

export const PHONE_SUPPORT = "(480) 800-7244";
export const PHONE_INFO = "(855) 443-3283";

export const API_BASE_URL = "https://innoventive-app-jqzpqmk45a-uc.a.run.app";
export const WEB_BASE_URL = "https://innoventive.milesoft.app";
export const WEBSITE_URL = "https://innovationdevelopmentteam.com";

export const CLOUDINARY_ID = "cuteandfuzzy";

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyA9Dj9SL_KXAQw4DfB-66XmJvyAUytOMdo",
    authDomain: "cf-notifier.firebaseapp.com",
    projectId: "cf-notifier",
    storageBucket: "cf-notifier.appspot.com",
    messagingSenderId: "741330969895",
    appId: "1:741330969895:web:795f316c4aca845967f502",
    measurementId: "G-7YK8FMDCTM"
};

export const NAMESPACE = "innoventive";
export const KEY_VERSION = "version";
export const KEY_AUTH_TOKEN = "auth_token";
export const KEY_TEMPORARY_PASSWORD = "temp_code";
export const KEY_STRIPE = "stripe";

export const UNANSWERED = "unanswered";

export const STRIPE_KEY = "pk_live_51KvlJJKHFJ7qGn5D0hLJPqDIqwLLmBGGdnESTHD54gV7oGc9MXCc03Rfa8j14aPnyJbI0PIVlJbEySQjowmSY0o300K6FYSzao";

export const STRIPE_PARAM_CLIENT_SECRET = "payment_intent_client_secret";

export const STRIPE_ERROR_VALIDATION = "validation_error";
export const STRIPE_STATUS_SUCCESS = "succeeded";
export const STRIPE_STATUS_PROCESSING = "processing";
export const STRIPE_STATUS_PAYMENT_FAILED = "requires_payment_method";

export const SERVICES_SUPREME = "Supreme";
export const SERVICES_PATENT_SEARCH = "PatentSearch";
export const SERVICES_PRODUCT_ANALYSIS = "ProductAnalysis";
export const SERVICES_BOTH = "Both";
export const SERVICES_UPSELL_ANALYSIS = "upsell_analysis";

export const MARKETING_PRODUCT_IMAGES = "ProductImages";
export const MARKETING_PATENT_ILLUSTRATIONS = "PatentIllustrations";
export const MARKETING_BUNDLE = "Bundle";

export const BUNDLE_BRONZE = "Bronze";
export const BUNDLE_SILVER = "Silver";
export const BUNDLE_GOLD = "Gold";

export const PRICES = {
    [SERVICES_SUPREME]: {
        dollars: 6999,
        cents: 0,
    },
    [SERVICES_PATENT_SEARCH]: {
        dollars: 299,
        cents: 0,
    },
    [SERVICES_PRODUCT_ANALYSIS]: {
        dollars: 399,
        cents: 0,
    },
    [SERVICES_BOTH]: {
        dollars: 599,
        cents: 0,
    },
    [SERVICES_UPSELL_ANALYSIS]: {
        dollars: 399,
        cents: 0,
    },
    [MARKETING_PRODUCT_IMAGES]: {
        dollars: 449,
        cents: 0,
    },
    [MARKETING_PATENT_ILLUSTRATIONS]: {
        dollars: 399,
        cents: 0,
    },
    [BUNDLE_BRONZE]: {
        dollars: 399,
        cents: 0,
    },
    [BUNDLE_SILVER]: {
        dollars: 599,
        cents: 0,
    },
    [BUNDLE_GOLD]: {
        dollars: 899,
        cents: 0,
    },
};

export const AFFILIATE = {
    firstName: "Trista",
    lastName: "Wold",
};

export const TAG_OTHER = "other";
export const TAG_SEARCH = "search";
export const TAG_MARKETING = "marketing-licencing";
export const TAGS = {
    "contracts-agreements": "Contracts & Agreements",
    [TAG_SEARCH]: "Search",
    "evaluation": "Evaluation",
    [TAG_MARKETING]: "Marketing Licencing",
    "prototyping": "Prototyping",
    "renditions": "Renditions",
    "youtube-video": "YouTube Video",
    "manufacturing-consulting": "Manufacturing Consulting",
    "business-consulting": "Business Consulting",
    "venture-capital-presentation": "Venture Capital Presentation",
    "patent-illustrations": "Patent Illustrations",
    "patent": "Patent",
    "trademark": "Trademark",
    "accounting": "Accounting",
    "help": "Help",
    [TAG_OTHER]: "Other",
};

export const STEP_NULL = -1;
export const STEP_DONE = 2022;

// getting started
export const STEP_START_WELCOME = 0;
export const STEP_START_PROFILE = 1;
export const STEP_START_SIGN_NDA = 2;
export const STEP_START_INVENTION = 3;
export const STEP_START_STORE = 4;

// search
export const STEP_SEARCH_SIGN_AGREEMENT = 0;
export const STEP_SEARCH_SIGN_RESULTS = 1;
export const STEP_SEARCH_UPSELLS = 2;

// analysis
export const STEP_ANALYSIS_QUESTIONNAIRE = 0;
export const STEP_ANALYSIS_RESULTS = 1;
export const STEP_ANALYSIS_UPSELLS = 2;

// both
export const STEP_BOTH_SIGN_SEARCH = 0;
export const STEP_BOTH_SIGN_SEARCH_RESULTS = 1;
export const STEP_BOTH_ANALYSIS_QUESTIONNAIRE = 2;
export const STEP_BOTH_ANALYSIS_RESULTS = 3;
export const STEP_BOTH_UPSELLS = 4;

// marketing
export const STEP_MARKETING_STORE = 0;
export const STEP_MARKETING_THANKS = 1;

// sell-sheet
export const STEP_PRODUCT_INTRO = 0;
export const STEP_PRODUCT_IMAGES = 1;
export const STEP_PRODUCT_TEXT = 2;
export const STEP_PRODUCT_THANKS = 3;

export const BRANDING_LIGHT = {
    primary: "#0072E5",
    secondary: "#CD7F32",
    background: "#FEFEFE",
};

export const BRANDING_DARK = {
    primary: "#0072E5",
    secondary: "#CD7F32",
    background: "#222222",
};

export const FIREWORKS_STYLE = {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    position: "fixed",
    background: "transparent",
    zIndex: -10,
};

export const FIREWORKS_OPTIONS = {
    delay: {
        min: 80,
        max: 200,
    }
};

export const NOTIFICATION_TYPES = {
    account_joined: {
        admin: true,
        short: "New User",
        long: "A new user signs up",
        delivery: notifications.DELIVERY_APP,
    },
    question_asked: {
        admin: true,
        short: "Question Asked",
        long: "A question has been asked",
        delivery: notifications.DELIVERY_APP,
    },
    access_granted: {
        short: "Access Granted",
        long: "Access is granted to shared files",
        delivery: notifications.DELIVERY_APP,
    },
    access_revoked: {
        short: "Access Revoked",
        long: "Access is revoked to shared files",
        delivery: notifications.DELIVERY_APP,
    },
    download: {
        short: "Download",
        long: "A file is downloaded",
        delivery: notifications.DELIVERY_APP,
    },
    upload: {
        short: "Upload",
        long: "A file is uploaded",
        delivery: notifications.DELIVERY_APP,
    },
    question_answered: {
        admin: false,
        short: "Question Answered",
        long: "Your question has been answered",
        delivery: notifications.DELIVERY_APP,
    },
    purchase_confirmation: {
        admin: false,
        short: "Purchase Confirmation",
        long: "Confirmation of your purchases",
        delivery: notifications.DELIVERY_APP,
    },
    signed_non_disclosure: {
        admin: true,
        short: "Signed NDA",
        long: "Someone signs the NDA",
        delivery: notifications.DELIVERY_APP,
    },
    submitted_invention: {
        admin: true,
        short: "Invention Questionnaire Submitted",
        long: "Someone submits the invention questionnaire",
        delivery: notifications.DELIVERY_APP,
    },
    purchase: {
        admin: true,
        short: "Purchase",
        long: "Someone purchases something",
        delivery: notifications.DELIVERY_APP,
    },
    signed_search: {
        admin: true,
        short: "Signed Search Agreement",
        long: "Someone signs the search agreement",
        delivery: notifications.DELIVERY_APP,
    },
    signed_search_results: {
        admin: true,
        short: "Signed Search Results Agreement",
        long: "Someone signs the search results agreement",
        delivery: notifications.DELIVERY_APP,
    },
    submitted_analysis: {
        admin: true,
        short: "Analysis Questionnaire Submitted",
        long: "Someone submits the analysis questionnaire",
        delivery: notifications.DELIVERY_APP,
    },
    signed_analysis_results: {
        admin: true,
        short: "Signed Analysis Results Agreement",
        long: "Someone signs the analysis results agreement",
        delivery: notifications.DELIVERY_APP,
    },
    submitted_upsell: {
        admin: true,
        short: "Upsell Questionnaire Submitted",
        long: "Someone submits the upsell questionnaire",
        delivery: notifications.DELIVERY_APP,
    },
    submitted_sell_sheet: {
        admin: true,
        short: "Sell-Sheet Submitted",
        long: "Someone submits sell-sheet data",
        delivery: notifications.DELIVERY_APP,
    },
};

export const TOPICS = {};

// standard public pages
export const PAGE_LOGIN = "/login";
export const PAGE_RECOVERY = "/recovery";
export const PAGE_WELCOME = "/welcome";
export const PAGE_NEW_USER = "/newUser";

// custom public pages
export const PAGE_WELCOME_NEW = "/welcomeNew";

// standard private pages
export const PAGE_PROFILE = "/profile";
export const PAGE_OPTIONS = "/options";
export const PAGE_PASSWORD = "/password";
export const PAGE_ABOUT = "/about";
export const PAGE_USERS = "/users";
export const PAGE_USER = "/user";
export const PAGE_NOTIFICATIONS = "/notifications";

// custom private pages
export const PAGE_DOCUMENTS = "/documents";
export const PAGE_HELPS = "/helps";
export const PAGE_WIZARD = "/wizard";
export const PAGE_TASKS = "/tasks";
export const PAGE_TASK = "/task";

export const PAGES_MAIN = [
    PAGE_NOTIFICATIONS,
    PAGE_DOCUMENTS,
    PAGE_HELPS,
    PAGE_TASKS,
    PAGE_WIZARD,
];

export const PAGES_MORE = [
    PAGE_USERS,
    PAGE_PROFILE,
    PAGE_OPTIONS,
    PAGE_PASSWORD,
    PAGE_ABOUT,
];

export const PRIVATE_PAGES_EXACT = [].concat(PAGES_MAIN).concat(PAGES_MORE);

export const PRIVATE_PAGES_STARTS_WITH = [
    PAGE_USER,
    PAGE_TASK,
];

export const PUBLIC_PAGES_EXACT = [
    PAGE_LOGIN,
    PAGE_RECOVERY,
    PAGE_WELCOME,
    PAGE_NEW_USER,
    PAGE_WELCOME_NEW,
];

export const PUBLIC_PAGES_STARTS_WITH = [];
