import axios from 'axios';
import {cursors} from "@atttomyx/shared-utils";
import {limits} from "@atttomyx/shared-constants";

export const findQuestions = (userId, success, failure) => {
    const endpoint = `list/${userId}/`;
    const loader = (cursor, limit, success, failure) => listQuestions(endpoint, cursor, limit, success, failure);

    cursors.loadAll(loader, "helps", limits.MEDIUM, success, failure);
};

export const listMyQuestions = (cursor, limit, success, failure) => {
    listQuestions("mine", cursor, limit, success, failure);
};

export const listUnansweredQuestions = (cursor, limit, success, failure) => {
    listQuestions("unanswered", cursor, limit, success, failure);
};

const listQuestions = (endpoint, cursor, limit, success, failure) => {
    let url = `/api/v1/help/${endpoint}?limit=` + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const askQuestion = (question, success, failure) => {
    const url = "/api/v1/help/ask";

    axios.post(url, {
        question: question,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const answerQuestion = (helpId, answer, success, failure) => {
    const url = "/api/v1/help/" + helpId + "/answer";

    axios.put(url, {
        answer: answer,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};
