import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {AppBar, Badge, BottomNavigation, BottomNavigationAction} from "@mui/material";
import {
    Assignment as TasksIcon,
    AutoFixHigh as WizardIcon,
    FolderRounded as FilesIcon,
    MoreHoriz as MoreIcon,
    Notifications as NotificationsIcon,
    QuestionAnswerRounded as HelpsIcon
} from "@mui/icons-material";
import MoreMenu from "../moreMenu/moreMenu";
import {router} from "@atttomyx/react-utils";
import {arrays} from "@atttomyx/shared-utils";
import {
    PAGE_DOCUMENTS,
    PAGE_HELPS,
    PAGE_NOTIFICATIONS,
    PAGE_TASK,
    PAGE_TASKS,
    PAGE_USER,
    PAGE_WIZARD,
    PAGES_MAIN,
    PAGES_MORE
} from "../../constants";
import "./footer.css";

const PAGE_MORE = "more";
const PAGE_OTHER = "other";

const Footer = (props) => {
    const { history, user, numNotifications, numQuestions, numTasks, onLogout } = props;
    const [showMore, setShowMore] = useState(false);

    const path = router.getCurrentPage(history);
    let page = PAGE_OTHER;

    if (arrays.contains(PAGES_MAIN, path)) {
        page = path;

    } else if (arrays.contains(PAGES_MORE, path)) {
        page = PAGE_MORE;

    } else if (path.startsWith(PAGE_USER + "/")) {
        page = PAGE_MORE;

    } else if (path.startsWith(PAGE_TASK + "/")) {
        page = PAGE_TASKS;
    }

    return <div className="footer">
        <AppBar className="bottom-nav" position="fixed" color="default">
            <BottomNavigation
                value={page}
                showLabels={true}
            >
                <BottomNavigationAction
                    value={PAGE_NOTIFICATIONS}
                    label="Notifications"
                    icon={
                        <Badge
                            color="error"
                            badgeContent={numNotifications}
                            invisible={numNotifications === 0}>
                            <NotificationsIcon/>
                        </Badge>}
                    onClick={() => router.redirectTo(history, PAGE_NOTIFICATIONS)}
                />
                <BottomNavigationAction
                    value={PAGE_HELPS}
                    label="Questions"
                    icon={
                        <Badge
                            color="error"
                            badgeContent={numQuestions}
                            invisible={numQuestions === 0}>
                            <HelpsIcon/>
                        </Badge>}
                    onClick={() => router.redirectTo(history, PAGE_HELPS)}
                />
                <BottomNavigationAction
                    value={PAGE_DOCUMENTS}
                    label="Files"
                    icon={<FilesIcon/>}
                    onClick={() => router.redirectTo(history, PAGE_DOCUMENTS)}
                />
                {user.roles.admin ?
                    <BottomNavigationAction
                        value={PAGE_TASKS}
                        label="Tasks"
                        icon={
                            <Badge
                                color="error"
                                badgeContent={numTasks}
                                invisible={numTasks === 0}>
                                <TasksIcon/>
                            </Badge>}
                        onClick={() => router.redirectTo(history, PAGE_TASKS)}
                    /> :
                    <BottomNavigationAction
                        value={PAGE_WIZARD}
                        label="Wizard"
                        icon={<WizardIcon/>}
                        onClick={() => router.redirectTo(history, PAGE_WIZARD)}
                    />}
                <BottomNavigationAction
                    value={PAGE_MORE}
                    label="More"
                    icon={<MoreIcon/>}
                    component="div"
                    onClick={() => setShowMore(true)}
                />
            </BottomNavigation>
        </AppBar>
        {showMore ?
            <MoreMenu
                user={user}
                onCancel={() => setShowMore(false)}
                onLogout={onLogout}
            /> : null}
    </div>
}

export default withRouter(Footer);
