import React, {useState} from "react";
import {Button, Typography} from "@mui/material";
import YourFilesBlurb from "../../components/yourFilesBlurb/yourFilesBlurb";
import NdaDialog from "../../dialogs/ndaDialog/ndaDialog";

const NdaStep = (props) => {
    const { snackbar, user, onSaveWizard, onSaveDocument } = props;
    const [ showDialog, setShowDialog ] = useState(false);

    return <>
        <Typography>
            Please complete the NDA
        </Typography>
        <YourFilesBlurb/>
        <div className="action">
            <Button
                color="primary"
                onClick={() => setShowDialog(true)}
            >
                Sign NDA
            </Button>
        </div>
        {showDialog ?
            <NdaDialog
                snackbar={snackbar}
                user={user}
                onCancel={() => setShowDialog(false)}
                onSave={(wizard, pdf) => {
                    setShowDialog(false);
                    onSaveWizard(wizard);
                    onSaveDocument(pdf);
                }}
            /> : null}
    </>
};

export default NdaStep;
