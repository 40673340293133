import {filters} from "@atttomyx/shared-constants";
import {renderTag} from "./utils/tags";

export const USER_FILTERS = {
    firstName: {
        type: filters.TYPE_STRING,
    },
    lastName: {
        type: filters.TYPE_STRING,
    },
    role: {
        type: filters.TYPE_BOOLEAN,
        getter: (item) => item.roles.admin,
        formatter: (value) => value === "true" ? "Admin" : "User",
    },
};

export const DOCUMENT_FILTERS = {
    tag: {
        type: filters.TYPE_ARRAY,
        label: "category",
        getter: (item) => item.tags,
        formatter: (value) => renderTag(value),
    },
    fileName: {
        type: filters.TYPE_STRING,
        label: "name",
    },
    size: {
        type: filters.TYPE_STRING,
    },
};

export const getHelpFilters = (userRenderer) => {
    return {
        userId: {
            type: filters.TYPE_STRING,
            label: "user",
            formatter: (value) => userRenderer.render(value),
        },
        answer: {
            type: filters.TYPE_EXISTS,
            label: "answered",
        },
    }
};

export const getTaskFilters = (userRenderer) => {
    return {
        title: {
            type: filters.TYPE_STRING,
        },
        description: {
            type: filters.TYPE_STRING,
        },
        onBehalfOfUserId: {
            type: filters.TYPE_STRING,
            label: "for",
            formatter: (value) => userRenderer.render(value),
        },
        userId: {
            type: filters.TYPE_STRING,
            label: "owner",
            formatter: (value) => userRenderer.render(value),
        },
        assigned: {
            type: filters.TYPE_EXISTS,
            label: "assigned",
            getter: (item) => item.userId,
        },
        completed: {
            type: filters.TYPE_EXISTS,
        },
    }
};

export const TASK_FILTER_DEFAULTS = {
    completed: filters.FALSE,
};
