import React, {useState} from "react";
import {Typography} from "@mui/material";
import YourFilesLink from "../../components/yourFilesLink/yourFilesLink";
import SpinnerButton from "../../components/spinnerButton/spinnerButton";
import QuestionsBlurb from "../../components/questionsBlurb/questionsBlurb";
import * as wizardService from "../../services/wizards";

const ProductThanksStep = (props) => {
    const { snackbar, onSaveWizard } = props;
    const [ saving, setSaving ] = useState(false);

    const markComplete = () => {
        setSaving(true);

        const success = (wizard) => {
            onSaveWizard(wizard);
            setSaving(false);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        }

        wizardService.markComplete(success, failure);
    };

    return <>
        <Typography variant="h6" paragraph={true}>
            Thank you!
        </Typography>
        <Typography paragraph={true}>
            Your sell-sheet will be uploaded to <YourFilesLink/> soon.
        </Typography>
        <QuestionsBlurb/>
        <SpinnerButton
            color="primary"
            label="Ok"
            onClick={markComplete}
            spinner={saving}
        />
    </>
};

export default ProductThanksStep;
