import React from "react";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import "./genderField.css";

const MALE = "Male";
const FEMALE = "Female";

const GenderField = (props) => {
    const {value, onChange} = props;

    return <div className="gender-field">
        <RadioGroup row={true} aria-labelledby="gender"
                    onChange={(event) => onChange(event.target.value)}>
            <FormControlLabel
                value={MALE}
                label={MALE}
                control={<Radio checked={value === MALE} color="primary"/>}
            />
            <FormControlLabel
                value={FEMALE}
                label={FEMALE}
                control={<Radio checked={value === FEMALE} color="primary"/>}
            />
        </RadioGroup>
    </div>
}

export default GenderField;
