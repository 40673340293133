import React from "react";
import {Typography} from "@mui/material";
import {EmailAddress, PhoneNumber} from "@atttomyx/react-components";
import {EMAIL_INFO, PHONE_INFO} from "../../constants";
import "./contactUs.css";

const ContactUs = () => {
    return <div className="contact-us">
        <Typography component="p">
            email: <EmailAddress email={EMAIL_INFO}/>
        </Typography>
        <Typography component="p">
            or Call us toll free at <PhoneNumber phone={PHONE_INFO}/>
        </Typography>
    </div>
};

export default ContactUs;
