import React, {useState} from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import SignatureCanvas from "react-signature-canvas";
import {Asterisk, ClosableDialogTitle, EmailAddress} from "@atttomyx/react-components";
import SearchFields from "../../forms/searchFields/searchFields";
import {datetime, money, users} from "@atttomyx/shared-utils";
import * as wizardService from "../../services/wizards";
import {AFFILIATE, COMPANY_NAME, EMAIL_SUPPORT, PHONE_SUPPORT, PRICES, SERVICES_PATENT_SEARCH} from "../../constants";
import "./searchDialog.css";

const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: "900px",
    },
}));

const appendTo = (str1, str2) => {
    const appended = str1 ? str1 + " " + str2 : str2;

    return str2 ? appended : str1;
}

const renderAddress = (address) => {
    let rendered = "";

    rendered = appendTo(rendered, address.line1);
    rendered = appendTo(rendered, address.line2);
    rendered = appendTo(rendered, address.locality);
    rendered = appendTo(rendered, address.region);
    rendered = appendTo(rendered, address.postalCode);
    rendered = appendTo(rendered, address.country);

    return rendered;
};

const toData = (user, invention) => {
    const settings = user.settings || {};
    const address = settings.address || {};

    return {
        firstName: user.firstName,
        lastName: user.lastName,
        address: renderAddress(address),
        email: user.email,
        phone1: user.phone,
        phone2: null,
        inventors: null,
        title: invention.title,
        purpose: invention.purpose,
        howToUse: invention.howToUse,
        description: invention.description,
        uniqueness: invention.uniqueness,
        knowledge: null,
        additional: null,
        valid: true,
    }
};

const SearchDialog = (props) => {
    const {snackbar, user, invention, onCancel, onSave} = props;
    const [data, setData] = useState(toData(user, invention));
    const [canvas, setCanvas] = useState(null);
    const [signature, setSignature] = useState(null);
    const [saving, setSaving] = useState(false);

    const affiliateName = users.getFullName(AFFILIATE);
    const today = datetime.today();
    const nextWeek = datetime.nextWeek(today);
    const price = money.render(PRICES[SERVICES_PATENT_SEARCH]);

    const onStrokeEnd = () => {
        if (canvas && !canvas.isEmpty()) {
            setSignature(canvas.toDataURL());
        }
    };

    const submitForm = () => {
        setSaving(true);

        const success = (wizard, pdf) => {
            snackbar.setSuccess("Agreement signed");
            setSaving(false);
            onSave(wizard, pdf);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        wizardService.signSearchAgreement(signature, data, success, failure);
    };

    return <Dialog open={true} classes={useStyles()}>
        <ClosableDialogTitle onClose={onCancel}>
            Patent Search Agreement
        </ClosableDialogTitle>
        <DialogContent className="search-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <>
                    <Typography paragraph={true}>
                        <strong>INTRO</strong>
                    </Typography>
                    <Typography paragraph={true}>
                        Thank you for contacting us to engage our services to provide you with search results on your inventive
                        concept. Any information you share with us is considered strictly confidential.
                    </Typography>
                    <Typography paragraph={true}>
                        A flat fee of {price} is being charged for providing you with a patent search on your disclosed
                        invention-idea. Our scope of representation for this portion of the process includes a comprehensive
                        search using various patent search engines to uncover the six (6) closest issued U.S. patents, published
                        U.S. patent applications, and foreign patent documents in relation to your invention disclosure. These
                        results are then organized into a search report for your review and to provide you with additional
                        information about the U.S. patent system. {COMPANY_NAME} does not provide patent legal
                        services and gives no legal advice on such matters. We can refer you to a list of Patent Attorneys and
                        Agents that we have worked with, or you can select the Patent Attorney or Agent of your choosing. It is
                        advisable to closely review the search results, when returned to you and discuss any legal questions with a
                        Patent Attorney or Agent to help determine whether it is in your best interest to move forward with the
                        appropriate patent protection in place. Time is of the essence since the United States is a "first to file"
                        patent system. In a "first to file" system, the right to the grant of a patent for a given invention lies with
                        the first person to file a patent application for protection of that invention, regardless of the date of actual
                        invention. Your potential patent rights may be adversely affected by any attempt to commercialize your
                        idea or invention before a patent application covering it is filed. You will also likely wish to have an
                        evaluation report performed by us, to help in combination with the search, to determine early on in the
                        process, the potential commercial viability of your invention.
                    </Typography>
                    <Typography paragraph={true}>
                        <strong>INSTRUCTIONS:</strong> Fill each blank with the requested information or enter "N/A" as appropriate. The
                        data previously provided to us has been pre-populated into this form for your convenience. Space is
                        provided at the end if the form fields are not adequate or if there have been changes made. When
                        completely executed, this form becomes an important document useful for development of your invention
                        and will remain in your file. If you have any questions, contact us at {PHONE_SUPPORT} or via e-mail at&nbsp;
                        <EmailAddress email={EMAIL_SUPPORT}/>.
                    </Typography>
                    <SearchFields
                        data={data}
                        onChange={setData}
                    />
                    <Typography paragraph={true}>
                        By clicking on "sign electronically", as the inventor (Receiving Party), I understand that I am entering
                        into a legally binding Agreement with <strong>{COMPANY_NAME}</strong> to review the above provided
                        information and to perform a patent search and provide you with feedback on your personal portal and via
                        email. Payment for this service ({price}) is considered earned upon completion and is not based upon
                        positive or negative results. You can expect to receive your results back within 10 business days from the
                        date of this signing. Receiving Party can choose to re-engage with <strong>{COMPANY_NAME}</strong> to
                        order further services such as (renditions or promotional videos, patent illustrations, prototyping,
                        marketing, venture capital presentations, business and/or manufacturing consulting) on the invention or to
                        consult with other parties of your choosing. Before signing this document and thereby entering into this
                        Agreement you are encouraged to consult with advisors of your choosing. The information contained
                        herein and to be returned to you is not legal advice, no legal relationship has been created.
                        <strong>{COMPANY_NAME}</strong> is willing to provide you with names of Patent Attorneys or Patent Agents that can
                        provide you with legal advice, or you may select a Patent Attorney or Patent Agent of your choosing. The
                        seven-day period during which you may cancel this contract for any reason by mailing or delivering
                        written notice to the invention developer (Receiving Party) will expire on {nextWeek}.
                        If you choose to mail your notice, it must be placed in the United States
                        mail properly addressed first-class postage prepaid and postmarked before midnight of this date. If you choose
                        to deliver your notice to the invention developer directly, it must be delivered to him by the end of
                        his normal business day on this date. The invention developer also has the right to cancel this contract by
                        notice similarly mailed or delivered.
                    </Typography>
                    <Table size="small" cellPadding={0} cellSpacing={0}>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <strong>Disclosing Party:</strong>
                                </TableCell>
                                <TableCell>
                                    <SignatureCanvas
                                        ref={setCanvas}
                                        canvasProps={{width: 600, height: 100, className: "signature"}}
                                        onEnd={onStrokeEnd}
                                    />
                                </TableCell>
                                <TableCell className="underlined">
                                    &nbsp;&nbsp;{today}&nbsp;&nbsp;
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <strong>Receiving Party:</strong>
                                </TableCell>
                                <TableCell>
                                    {COMPANY_NAME}
                                </TableCell>
                                <TableCell>
                                    &nbsp;
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    &nbsp;
                                </TableCell>
                                <TableCell className="underlined">
                                    &nbsp;&nbsp;{affiliateName}&nbsp;&nbsp;
                                </TableCell>
                                <TableCell>
                                    &nbsp;
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </>}
        </DialogContent>
        {!saving ? <DialogActions style={{
            marginTop: "10px",
        }}>
            <Asterisk/>
            <Button color="primary"
                    disabled={!signature || !data.valid}
                    onClick={submitForm}>
                Sign Electronically
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default SearchDialog;
