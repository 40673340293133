import {objects, users} from "@atttomyx/shared-utils";
import {themes} from "@atttomyx/shared-constants";
import {PAGE_NOTIFICATIONS, PAGE_WIZARD} from "../constants";

export const sanitizeUser = (user) => {
    user.roles = users.normalizeRoles(user.roles);
    user.settings = sanitizeSettings(user.settings);
};

export const sanitizeProfile = (profile) => {
    profile.settings = sanitizeSettings(profile.settings);
};

const sanitizeSettings = (settings) => {
    const sanitized = settings || {};

    sanitized.theme = objects.defaultIfNullOrUndefined(sanitized.theme, themes.LIGHT);
    // todo: implement

    return sanitized;
};

export const getHomePage = (user) => {
    return user.roles.admin ? PAGE_NOTIFICATIONS : PAGE_WIZARD
};
