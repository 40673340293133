import React, {useEffect} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {Link} from "@mui/material";
import {
    useBootstrap,
    useCachedByOwnerId,
    useEntities,
    useEntity,
    useFilters,
    useProgress,
    useRenderer
} from "@atttomyx/shared-hooks";
import {usePushEvent} from "@atttomyx/react-hooks";
import {
    AboutPage,
    AddUserPage,
    Asterisk,
    ChangePasswordPage,
    EditUserPage,
    ForgotPasswordPage,
    LoginPage,
    NewUserPage,
    NotFoundPage,
    NotificationsPage,
    ProfilePage,
    PublicHeader,
    Splash,
    UserSettingsPage,
    UsersPage,
    WelcomePage
} from "@atttomyx/react-components";
import Logo from "./components/logo/logo";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import MoreMenu from "./components/moreMenu/moreMenu";
import UserSettingsFields from "./forms/userSettingsFields/userSettingsFields";
import UserCustomFields from "./forms/userCustomFields/userCustomFields";
import DocumentsPage from "./pages/documentsPage/documentsPage";
import HelpsPage from "./pages/helpsPage/helpsPage";
import WizardPage from "./pages/wizardPage/wizardPage";
import WelcomeNewPage from "./pages/welcomeNewPage/welcomeNewPage";
import TasksPage from "./pages/tasksPage/tasksPage";
import TaskPage from "./pages/taskPage/taskPage";
import * as appService from "./services/app";
import * as authService from "./services/auth";
import * as accountService from "./services/account";
import * as notificationService from "./services/notifications";
import * as profileService from "./services/profile";
import * as userService from "./services/users";
import * as helpService from "./services/helps";
import * as documentService from "./services/documents";
import * as wizardService from "./services/wizards";
import * as taskService from "./services/tasks";
import {notifications as notificationUtils, sorting, strings, users as userUtils} from "@atttomyx/shared-utils";
import {firebase as firebaseUtils, router} from "@atttomyx/react-utils";
import {getHomePage} from "./utils/users";
import {isDue, sortByDue} from "./utils/tasks";
import {themes, time} from "@atttomyx/shared-constants";
import {DOCUMENT_FILTERS, getHelpFilters, getTaskFilters, TASK_FILTER_DEFAULTS, USER_FILTERS} from "./filters";
import {
    APP_NAME,
    APP_TAG_LINE,
    BRANDING_DARK,
    BRANDING_LIGHT, FIREBASE_CONFIG,
    NOTIFICATION_TYPES,
    PAGE_ABOUT,
    PAGE_DOCUMENTS,
    PAGE_HELPS,
    PAGE_LOGIN,
    PAGE_NEW_USER,
    PAGE_NOTIFICATIONS,
    PAGE_OPTIONS,
    PAGE_PASSWORD,
    PAGE_PROFILE,
    PAGE_RECOVERY,
    PAGE_TASK,
    PAGE_TASKS,
    PAGE_USER,
    PAGE_USERS,
    PAGE_WELCOME,
    PAGE_WELCOME_NEW,
    PAGE_WIZARD,
    PRIVATE_PAGES_EXACT,
    PRIVATE_PAGES_STARTS_WITH,
    PUBLIC_PAGES_EXACT,
    PUBLIC_PAGES_STARTS_WITH,
    TOPICS,
    WEBSITE_URL,
} from "./constants";

const App = (props) => {
    const { snackbar, dimensions, history, onThemeChange } = props;
    const users = useEntities(userService.listUsers, snackbar.setError, "users", sorting.sortByLastNameAndFirstName);
    const notifications = useEntities(notificationService.listNotifications, snackbar.setError, "notifications", sorting.sortByCreatedDesc);
    const documents = useEntities(documentService.listMyDocuments, snackbar.setError, "documents", sorting.sortByUpdatedDesc);
    const sources = useEntities(documentService.listSources, snackbar.setError, "users", userUtils.sortByName);
    const targets = useEntities(documentService.listTargets, snackbar.setError, "users", userUtils.sortByName);
    const tasks = useEntities(taskService.listTasks, snackbar.setError, "tasks", sortByDue);
    const helpCache = useCachedByOwnerId(helpService.findQuestions, snackbar.setError, "helps", sorting.sortByCreatedDesc);
    const wizard = useEntity(wizardService.loadWizard, snackbar.setError);

    const userRenderer = useRenderer(users, userUtils.getFullName);

    const userFilters = useFilters(USER_FILTERS);
    const documentFilters = useFilters(DOCUMENT_FILTERS);
    const helpFilters = useFilters(getHelpFilters(userRenderer));
    const taskFilters = useFilters(getTaskFilters(userRenderer), TASK_FILTER_DEFAULTS);

    const helpsLoader = (cursor, limit, success, failure) => {
        if (bootstrap.user.roles.admin) {
            helpService.listUnansweredQuestions(cursor, limit, success, failure);

        } else {
            helpService.listMyQuestions(cursor, limit, success, failure);
        }
    };

    const documentsFinder = (userId, success, failure) => {
        if (bootstrap.user.roles.admin) {
            documentService.findDocuments(userId, success, failure);

        } else {
            documentService.findSharedDocuments(userId, success, failure);
        }
    };

    const helps = useEntities(helpsLoader, snackbar.setError, "helps", sorting.sortByCreatedDesc);
    const documentCache = useCachedByOwnerId(documentsFinder, snackbar.setError, "documents", sorting.sortByUpdatedDesc);

    const onLogout = () => {
        authService.cancelAllRequests("logged out");
        authService.clearAuthToken();

        progress.clear();
        bootstrap.clear();
        users.clear();
        notifications.clear();
        helps.clear();
        helpCache.clear();
        documents.clear();
        documentCache.clear();
        sources.clear();
        targets.clear();
        tasks.clear();
        wizard.clear();
        userFilters.clear();

        router.redirectToInitialPage(history, PUBLIC_PAGES_EXACT, PUBLIC_PAGES_STARTS_WITH, PAGE_LOGIN);
    };

    const bootstrapSuccess = () => {
        const temporaryPassword = authService.getTemporaryPassword();

        if (strings.isNotBlank(temporaryPassword)) {
            router.redirectTo(history, PAGE_PASSWORD);

        } else {
            const home = getHomePage(bootstrap.user);

            router.redirectToInitialPage(history, PRIVATE_PAGES_EXACT, PRIVATE_PAGES_STARTS_WITH, home);
        }

        notifications.refresh();
        helps.refresh();

        if (bootstrap.user.roles.admin) {
            users.refresh();
            documents.empty();
            sources.empty();
            targets.empty();
            tasks.refresh();
            wizard.empty();

        } else {
            users.empty();
            documents.refresh();
            sources.refresh();
            targets.refresh();
            tasks.empty();
            wizard.refresh();
        }
    };

    const bootstrapFailure = (err) => {
        console.log(err);
        onLogout();
    };

    const bootstrap = useBootstrap(authService, accountService, profileService, notificationService, bootstrapSuccess, bootstrapFailure);

    const progress = useProgress(bootstrap,
        [users, notifications, helps, documents, sources, targets, tasks],
        [wizard],
        time.MILLISECONDS_IN_SECOND);

    const event = usePushEvent(bootstrap);

    const onLogin = (accounts) => {
        progress.refresh();
        bootstrap.refresh(accounts);
    };

    useEffect(() => {
        if (event && event.type) {
            const type = event.type;
            const data = event.payload || {};
            const payload = data.payload || {};
            const actor = data.actor || payload.actor || {};

            console.table(type, data);

            if (bootstrap.user.id !== actor.userId) {
                const userId = payload.sourceId || payload.userId;

                switch (type) {
                    case "user_saved":
                    case "user_deleted":
                        users.refresh();
                        break;
                    case "question_asked":
                    case "question_answered":
                        helpCache.clear();

                        if (bootstrap.user.roles.admin) {
                            helps.refresh();
                        }
                        break;
                    case "upload":
                    case "access_granted":
                    case "access_revoked":
                    case "signed_non_disclosure":
                    case "submitted_invention":
                    case "signed_search":
                    case "signed_search_results":
                    case "submitted_analysis":
                    case "signed_analysis_results":
                    case "submitted_upsell":
                    case "submitted_sell_sheet":
                        if (userId) {
                            documentCache.clear(userId);
                        }
                        break;
                    default:
                        break;
                }

                if (notificationUtils.isEnabled(bootstrap.user, type)) {
                    const title = data.title;

                    if (strings.isNotBlank(title)) {
                        snackbar.setInfo(title);
                    }

                    if (notificationUtils.isPersist(bootstrap.user, type)) {
                        notifications.refresh();
                    }
                }
            }
        }
    }, [event]);

    useEffect(() => {
        const theme = bootstrap.user ? bootstrap.user.settings.theme || themes.LIGHT : themes.LIGHT;
        const branding = themes.LIGHT !== theme ? BRANDING_DARK : BRANDING_LIGHT;

        onThemeChange(theme, branding);
    }, [bootstrap.user]);

    useEffect(() => {
        appService.ensureOnLatestVersion(false, null);
        authService.setupAxiosInterceptors(onLogout);
        notificationUtils.initNotifications(NOTIFICATION_TYPES, TOPICS);
        firebaseUtils.initFirebase(FIREBASE_CONFIG);

        if (authService.loggedIn()) {
            onLogin();

        } else {
            onLogout();
        }
    }, []);

    return <div className="app">
        {progress.loading || progress.paused ?
            <Splash
                percent={progress.percent}
                logo={<Logo/>}
                label={WEBSITE_URL}
            /> : bootstrap.user ?
                <>
                    <Header
                        dimensions={dimensions}
                        user={bootstrap.user}
                        onLogout={onLogout}
                        custom={<Link href={WEBSITE_URL}><Logo/></Link>}
                        moreMenu={MoreMenu}
                        color="default"
                    />
                    <div className="content">
                        <Switch>
                            <Route exact path="/">
                                <Redirect to={getHomePage(bootstrap.user)}/>
                            </Route>
                            <Route path={PAGE_NOTIFICATIONS}>
                                <NotificationsPage
                                    snackbar={snackbar}
                                    dimensions={dimensions}
                                    notifications={notifications.entities}
                                    onBulkSave={notifications.onEntitiesSaved}
                                    onDelete={notifications.onEntityDeleted}
                                    notificationService={notificationService}
                                />
                            </Route>
                            <Route path={PAGE_DOCUMENTS}>
                                <DocumentsPage
                                    snackbar={snackbar}
                                    user={bootstrap.user}
                                    users={users.entities}
                                    sources={sources.entities}
                                    targets={targets.entities}
                                    documents={documents.entities}
                                    documentCache={documentCache}
                                    filters={documentFilters}
                                    onUpload={documents.onEntitySaved}
                                    onShare={targets.onEntitySaved}
                                    onRevoke={targets.onEntityDeleted}
                                />
                            </Route>
                            <Route path={PAGE_HELPS}>
                                <HelpsPage
                                    snackbar={snackbar}
                                    dimensions={dimensions}
                                    user={bootstrap.user}
                                    users={users.entities}
                                    helps={helps.entities}
                                    helpCache={helpCache}
                                    filters={helpFilters}
                                    onAsk={helps.onEntitySaved}
                                    onAnswer={helps.onEntityDeleted}
                                />
                            </Route>
                            {!bootstrap.user.roles.admin ?
                                <Route path={PAGE_WIZARD}>
                                    <WizardPage
                                        snackbar={snackbar}
                                        user={bootstrap.user}
                                        wizard={wizard.entity}
                                        onSaveProfile={bootstrap.syncProfile}
                                        onSaveWizard={wizard.onSave}
                                        onSaveDocument={documents.onEntitySaved}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.admin ?
                                <Route path={PAGE_TASKS}>
                                    <TasksPage
                                        snackbar={snackbar}
                                        dimensions={dimensions}
                                        users={users.entities}
                                        userRenderer={userRenderer}
                                        tasks={tasks.entities}
                                        filters={taskFilters}
                                        onSave={tasks.onEntitySaved}
                                        onDelete={tasks.onEntityDeleted}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.admin ?
                                <Route
                                    path={`${PAGE_TASK}/:id`}
                                    render={(props) =>
                                        <TaskPage
                                            {...props}
                                            user={bootstrap.user}
                                            snackbar={snackbar}
                                            tasks={tasks.entities}
                                            documentCache={documentCache}
                                            onSave={tasks.onEntitySaved}
                                        />}
                                /> : null}
                            <Route path={PAGE_PROFILE}>
                                <ProfilePage
                                    snackbar={snackbar}
                                    user={bootstrap.user}
                                    onSave={bootstrap.syncProfile}
                                    profileService={profileService}
                                    showImage={true}
                                    settingsCards={true}
                                    settingsForm={UserCustomFields}
                                    settingsProps={{
                                        notes: false,
                                        testMode: false,
                                    }}
                                />
                                <Asterisk style={{
                                    marginTop: "20px",
                                }}/>
                            </Route>
                            <Route path={PAGE_OPTIONS}>
                                <UserSettingsPage
                                    snackbar={snackbar}
                                    user={bootstrap.user}
                                    onSaveProfile={bootstrap.syncProfile}
                                    onSaveNotifications={bootstrap.syncNotifications}
                                    profileService={profileService}
                                    notificationService={notificationService}
                                    settingsForm={UserSettingsFields}
                                    allowApp={true}
                                    allowEmail={true}
                                    allowSms={false}
                                />
                                <Asterisk style={{
                                    marginTop: "20px",
                                }}/>
                            </Route>
                            <Route path={PAGE_PASSWORD}>
                                <ChangePasswordPage
                                    snackbar={snackbar}
                                    authService={authService}
                                    onChanged={() => router.redirectTo(history, PAGE_PROFILE)}
                                />
                                <Asterisk style={{
                                    marginTop: "20px",
                                }}/>
                            </Route>
                            <Route path={PAGE_ABOUT}>
                                <AboutPage
                                    dimensions={dimensions}
                                    appDescription={APP_TAG_LINE}
                                    appService={appService}
                                    custom={<Logo/>}
                                />
                            </Route>
                            {bootstrap.user.roles.admin ?
                                <Route path={PAGE_USERS}>
                                    <UsersPage
                                        snackbar={snackbar}
                                        dimensions={dimensions}
                                        user={bootstrap.user}
                                        users={users.entities}
                                        filters={userFilters}
                                        onDelete={users.onEntityDeleted}
                                        onGotoAdd={() => router.redirectTo(history, PAGE_USER)}
                                        onGotoProfile={() => router.redirectTo(history, PAGE_PROFILE)}
                                        onGotoUser={(userId) => router.redirectTo(history, `${PAGE_USER}/${userId}`)}
                                        userService={userService}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.admin ?
                                <Route exact path={PAGE_USER}>
                                    <AddUserPage
                                        snackbar={snackbar}
                                        onSave={(user) => {
                                            users.onEntitySaved(user);
                                            router.redirectTo(history, PAGE_USER + "/" + user.id);
                                        }}
                                        userService={userService}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.admin ?
                                <Route
                                    path={`${PAGE_USER}/:id`}
                                    render={(props) =>
                                        <EditUserPage
                                            {...props}
                                            snackbar={snackbar}
                                            user={bootstrap.user}
                                            users={users.entities}
                                            filters={userFilters}
                                            onSave={users.onEntitySaved}
                                            onPrev={(userId) => router.redirectTo(history, PAGE_USER + "/" + userId)}
                                            onNext={(userId) => router.redirectTo(history, PAGE_USER + "/" + userId)}
                                            userService={userService}
                                            showImage={true}
                                            settingsCards={true}
                                            settingsForm={UserCustomFields}
                                            settingsProps={{
                                                notes: true,
                                                testMode: true,
                                            }}
                                        />}
                                /> : null}
                            <Route>
                                <NotFoundPage/>
                            </Route>
                        </Switch>
                    </div>
                    <Footer
                        user={bootstrap.user}
                        onLogout={onLogout}
                        numNotifications={notifications.entities.filter(notificationUtils.isNewNotification).length}
                        numQuestions={bootstrap.user.roles.admin ? helps.entities.length : 0}
                        numTasks={tasks.entities.filter(isDue).length}
                    />
                </> :
                <>
                    <PublicHeader custom={<Logo/>}/>
                    <div className="content">
                        <Switch>
                            <Route path={PAGE_LOGIN}>
                                <LoginPage
                                    snackbar={snackbar}
                                    authService={authService}
                                    onForgotPassword={() => router.redirectTo(history, PAGE_RECOVERY)}
                                    onNewUser={() => router.redirectTo(history, PAGE_NEW_USER)}
                                    onLogin={onLogin}
                                />
                            </Route>
                            <Route path={PAGE_RECOVERY}>
                                <ForgotPasswordPage
                                    snackbar={snackbar}
                                    authService={authService}
                                    onBackToLogin={() => router.redirectTo(history, PAGE_LOGIN)}
                                    onLogin={onLogin}
                                    appName={APP_NAME}
                                />
                            </Route>
                            <Route path={PAGE_WELCOME_NEW}>
                                <WelcomeNewPage
                                    snackbar={snackbar}
                                    authService={authService}
                                    onToLogin={() => router.redirectTo(history, PAGE_LOGIN)}
                                    onLogin={onLogin}
                                />
                            </Route>
                            <Route path={PAGE_WELCOME}>
                                <WelcomePage
                                    snackbar={snackbar}
                                    authService={authService}
                                    onToLogin={() => router.redirectTo(history, PAGE_LOGIN)}
                                    onLogin={onLogin}
                                />
                            </Route>
                            <Route path={PAGE_NEW_USER}>
                                <NewUserPage
                                    snackbar={snackbar}
                                    authService={authService}
                                    accountService={accountService}
                                    onBackToLogin={() => router.redirectTo(history, PAGE_LOGIN)}
                                    onLogin={onLogin}
                                />
                            </Route>
                        </Switch>
                    </div>
                </>}
    </div>
}

export default withRouter(App);
