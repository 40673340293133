import axios from 'axios';
import * as profileService from "./profile";
import {users} from "@atttomyx/shared-utils";
import {sanitizeUser} from "../utils/users";

export const listUsers = (cursor, limit, success, failure) => {
    let url = "/api/v1/user/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
        .then(response => {
            const json = response.data;

            json.users.forEach(sanitizeUser);

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const createUser = (user, success, failure) => {
    const url = "/api/v1/user/";

    axios.post(url, {
        firstName: user.firstName,
        lastName: user.lastName,
        alias: user.alias,
        email: user.email,
        phone: user.phone,
        roles: users.standardizeRoles(user.roles),
        settings: user.settings,
    })
        .then(response => {
            const created = response.data;

            sanitizeUser(created);
            success(created);
        })
        .catch(err => {
            failure(err);
        });
};

export const saveUser = (userId, user, success, failure) => {
    const url = "/api/v1/user/" + userId + "/";

    axios.put(url, {
        firstName: user.firstName,
        lastName: user.lastName,
        alias: user.alias,
        email: user.email,
        phone: user.phone,
        imageUrl: user.imageUrl,
        roles: users.standardizeRoles(user.roles),
        settings: user.settings,
    })
        .then(response => {
            const saved = response.data;

            sanitizeUser(saved);
            success(saved);
        })
        .catch(err => {
            failure(err);
        });
};

export const deleteUser = (userId, success, failure) => {
    const url = "/api/v1/user/" + userId + "/";

    axios.delete(url)
        .then(response => {
            success(userId);
        })
        .catch(err => {
            failure(err);
        });
};

export const uploadImage = (userId, image, success, failure) => {
    profileService.uploadImage(userId, image, success, failure);
};
