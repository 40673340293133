import React from "react";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import ServiceCard from "../../cards/serviceCard/serviceCard";
import {objects, strings} from "@atttomyx/shared-utils";
import {
    SERVICES_BOTH,
    SERVICES_PATENT_SEARCH,
    SERVICES_PRODUCT_ANALYSIS,
    SERVICES_SUPREME,
    SERVICES_UPSELL_ANALYSIS
} from "../../constants";

const ServicesFields = (props) => {
    const {data, upsellAnalysis, onChange: onSync} = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = strings.isNotBlank(modified.item);

        onSync(modified);
    }

    return <div className="services-fields">
        <div className="field top-margin">
            <RadioGroup row={false} aria-labelledby="services"
                        onChange={(event) => onChange("item", event.target.value)}>
                {upsellAnalysis ?
                    <FormControlLabel
                        value={SERVICES_UPSELL_ANALYSIS}
                        label={<ServiceCard type={SERVICES_UPSELL_ANALYSIS}/>}
                        control={<Radio checked={data.item === SERVICES_UPSELL_ANALYSIS} color="primary"/>}
                    /> :
                    <>
                        <FormControlLabel
                            value={SERVICES_SUPREME}
                            label={<ServiceCard type={SERVICES_SUPREME}/>}
                            control={<Radio checked={data.item === SERVICES_SUPREME} color="primary"/>}
                        />
                        <FormControlLabel
                            value={SERVICES_PATENT_SEARCH}
                            label={<ServiceCard type={SERVICES_PATENT_SEARCH}/>}
                            control={<Radio checked={data.item === SERVICES_PATENT_SEARCH} color="primary"/>}
                        />
                        <FormControlLabel
                            value={SERVICES_PRODUCT_ANALYSIS}
                            label={<ServiceCard type={SERVICES_PRODUCT_ANALYSIS}/>}
                            control={<Radio checked={data.item === SERVICES_PRODUCT_ANALYSIS} color="primary"/>}
                        />
                        <FormControlLabel
                            value={SERVICES_BOTH}
                            label={<ServiceCard type={SERVICES_BOTH}/>}
                            control={<Radio checked={data.item === SERVICES_BOTH} color="primary"/>}
                        />
                    </>}
            </RadioGroup>
        </div>
    </div>
}

export default ServicesFields;
