import {COMPANY_NAME} from "../../constants";
import "./logo.css";
import logo from "./logo.png";

const Logo = (props) => {
    const { size } = props;
    const sizeToUse = size || "small";

    return <div className={"logo " + sizeToUse}>
        <img src={logo} alt={COMPANY_NAME}/>
    </div>
};

export default Logo;
