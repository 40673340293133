import React, {useState} from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import SignatureCanvas from "react-signature-canvas";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import {datetime, users} from "@atttomyx/shared-utils";
import * as wizardService from "../../services/wizards";
import {AFFILIATE, COMPANY_NAME} from "../../constants";
import "./ndaDialog.css";

const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: "900px",
    },
}));

const NdaDialog = (props) => {
    const {snackbar, user, onCancel, onSave} = props;
    const [canvas, setCanvas] = useState(null);
    const [signature, setSignature] = useState(null);
    const [saving, setSaving] = useState(false);

    const userName = users.getFullName(user);
    const affiliateName = users.getFullName(AFFILIATE);

    const onStrokeEnd = () => {
        if (canvas && !canvas.isEmpty()) {
            setSignature(canvas.toDataURL());
        }
    };

    const submitForm = () => {
        setSaving(true);

        const success = (wizard, pdf) => {
            snackbar.setSuccess("Agreement signed");
            setSaving(false);
            onSave(wizard, pdf);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        wizardService.signNda(signature, success, failure);
    };

    return <Dialog open={true} classes={useStyles()}>
        <ClosableDialogTitle onClose={onCancel}>
            Non-Disclosure Agreement
        </ClosableDialogTitle>
        <DialogContent className="nda-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <>
                    <Typography paragraph={true}>
                        This is an expressed agreement between <strong>{userName}</strong> ("Disclosing Party"), the inventor,
                        and <strong>{COMPANY_NAME}</strong> and employees, advisers and affiliates of {COMPANY_NAME} ("Receiving Party").
                    </Typography>
                    <Typography paragraph={true}>
                        The Disclosing Party has developed certain confidential proprietary information relating to a perceived
                        inventive concept which it wishes to remain confidential upon disclosure to the Receiving Party. The
                        Disclosing and Receiving Parties mutually desire such information be provided by the Disclosing Party to the
                        Receiving Party so that such information be used for subjective evaluation of the potential inventive
                        concept(s) of such intellectual property.
                    </Typography>
                    <Typography paragraph={true}>
                        All confidential information and intellectual property will remain the property of the Disclosing Party,
                        with rights intact. Nothing in this agreement is intended to grant any rights under any copyright or patent
                        to the Receiving Party. Receiving Party does not engage in providing patent legal services. The Receiving
                        Party recognizes the Disclosing Party’s interest in disclosing the inventive concept, in preserving the
                        proprietary and confidential nature of the information provided. Confidential and proprietary information is
                        revealed to the Receiving Party in strict confidence. Receiving Party shall not use, or induce others to
                        use, any of the confidential information provided for any purpose whatsoever, nor shall it disclose or
                        reveal any of the information disclosed.
                    </Typography>
                    <Typography paragraph={true}>
                        All confidential information and intellectual property will remain the property of the Disclosing Party,
                        with rights intact. Nothing in this agreement is intended to grant any rights under any copyright or patent
                        to the Receiving Party. Receiving Party does not engage in providing patent legal services. The Receiving
                        Party recognizes the Disclosing Party’s interest in disclosing the inventive concept, in preserving the
                        proprietary and confidential nature of the information provided. Confidential and proprietary information is
                        revealed to the Receiving Party in strict confidence. Receiving Party shall not use, or induce others to
                        use, any of the confidential information provided for any purpose whatsoever, nor shall it disclose or
                        reveal any of the information disclosed.
                    </Typography>
                    <Typography paragraph={true}>
                        Upon Disclosing Party’s request, Receiving Party will deliver over all information pertaining to the
                        Disclosing Party’s disclosure and proprietary property in the Receiving Party’s possession. Receiving Party
                        will receive the information and evaluate the product and provide feedback to Disclosing Party.
                    </Typography>
                    <Typography paragraph={true}>
                        The duties of the Receiving Party under this agreement to keep the Confidential Information confidential
                        shall expire two (2) years from the signing date of this agreement. The agreement shall be amended only
                        using a writing signed by both the Disclosing and Receiving Parties. This agreement shall be governed by and
                        construed in accordance with the laws of the State of Arizona. Any dispute under this Agreement that cannot
                        be settled by the parties themselves or with the assistance of a mediator within one month of notice of the
                        dispute will be settled by arbitration by a single arbitrator in accordance with the rules of the American
                        Arbitration Association, both mediation and arbitration to be held in Phoenix, AZ. Each party will pay its
                        own expenses of mediation and arbitration and equally share the mediator/arbitrator's fee.
                    </Typography>
                    <Typography paragraph={true}>
                        By clicking on "sign electronically" I understand that I am entering into a legally binding Agreement
                        with {COMPANY_NAME} to protect the confidentiality of my product idea for review. Before signing this
                        document and thereby entering into this Agreement you are encouraged to consult with advisors of your
                        choosing.
                    </Typography>
                    <Typography>
                        IN WITNESS WHEREOF:
                    </Typography>
                    <Typography paragraph={true}>
                        {COMPANY_NAME}
                    </Typography>
                    <Table size="small" cellPadding={0} cellSpacing={0}>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <strong>Disclosing Party:</strong>
                                </TableCell>
                                <TableCell>
                                    <SignatureCanvas
                                        ref={setCanvas}
                                        canvasProps={{width: 600, height: 100, className: "signature"}}
                                        onEnd={onStrokeEnd}
                                    />
                                </TableCell>
                                <TableCell className="underlined">
                                    &nbsp;&nbsp;{datetime.today()}&nbsp;&nbsp;
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <strong>Receiving Party:</strong>
                                </TableCell>
                                <TableCell>
                                    {COMPANY_NAME}
                                </TableCell>
                                <TableCell>
                                    &nbsp;
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    &nbsp;
                                </TableCell>
                                <TableCell className="underlined">
                                    &nbsp;&nbsp;{affiliateName}&nbsp;&nbsp;
                                </TableCell>
                                <TableCell>
                                    &nbsp;
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </>}
        </DialogContent>
        {!saving ? <DialogActions style={{
            marginTop: "10px",
        }}>
            <Button color="primary"
                    disabled={!signature}
                    onClick={submitForm}>
                Sign Electronically
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default NdaDialog;
