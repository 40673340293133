import axios from 'axios';
import {cursors} from "@atttomyx/shared-utils";
import {sanitizeUser} from "../utils/users";
import {limits} from "@atttomyx/shared-constants";

export const findDocuments = (userId, success, failure) => {
    const endpoint = `adminDocument/list/${userId}/`;
    const loader = (cursor, limit, success, failure) => listDocuments(endpoint, cursor, limit, success, failure);

    cursors.loadAll(loader, "documents", limits.MEDIUM, success, failure);
};

export const findSharedDocuments = (userId, success, failure) => {
    const endpoint = `sharedDocument/list/${userId}/`;
    const loader = (cursor, limit, success, failure) => listDocuments(endpoint, cursor, limit, success, failure);

    cursors.loadAll(loader, "documents", limits.MEDIUM, success, failure);
};

export const listMyDocuments = (cursor, limit, success, failure) => {
    const endpoint = "userDocument/list";

    listDocuments(endpoint, cursor, limit, success, failure);
};

const listDocuments = (endpoint, cursor, limit, success, failure) => {
    let url = `/api/v1/${endpoint}?limit=` + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const listSources = (cursor, limit, success, failure) => {
    findSources(success, failure);
}

const findSources = (success, failure) => {
    axios.get("/api/v1/sharedDocument/sources")
        .then(response => {
            const json = response.data;

            json.users.forEach(sanitizeUser);

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const listTargets = (cursor, limit, success, failure) => {
    findTargets(success, failure);
}

const findTargets = (success, failure) => {
    axios.get("/api/v1/sharedDocument/targets")
        .then(response => {
            const json = response.data;

            json.users.forEach(sanitizeUser);

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const downloadDocument = (userId, fileName, success, failure) => {
    const url = `/api/v1/adminDocument/download/${userId}/${fileName}/`;

    downloadFile(url, success, failure);
};

export const downloadSharedDocument = (userId, fileName, success, failure) => {
    const url = `/api/v1/sharedDocument/download/${userId}/${fileName}/`;

    downloadFile(url, success, failure);
};

export const downloadMyDocument = (fileName, success, failure) => {
    const url = `/api/v1/userDocument/download/${fileName}/`;

    downloadFile(url, success, failure);
};

const downloadFile = (url, success, failure) => {
    axios.get(url, {
        responseType: "arraybuffer",
    })
        .then(response => {
            const bytes = response.data;

            success(bytes);
        })
        .catch(err => {
            failure(err);
        });
};

export const uploadDocument = (userId, tag, file, success, failure) => {
    const url = `/api/v1/adminDocument/upload/${userId}/`;

    uploadFile(url, tag, file, success, failure);
};

export const uploadSharedDocument = (userId, tag, file, success, failure) => {
    const url = `/api/v1/sharedDocument/upload/${userId}/`;

    uploadFile(url, tag, file, success, failure);
};

export const uploadMyDocument = (tag, file, success, failure) => {
    const url = "/api/v1/userDocument/upload";

    uploadFile(url, tag, file, success, failure);
};

const uploadFile = (url, tag, file, success, failure) => {
    const data = new FormData();
    data.append("file", file);
    data.append("tag", tag);

    axios.post(url, data, {
        headers: {
            "X-Requested-With": "XMLHttpRequest",
        },
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const share = (email, success, failure) => {
    const url = "/api/v1/sharedDocument/share";

    axios.post(url, {
        email: email,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const revoke = (userId, success, failure) => {
    const url = `/api/v1/sharedDocument/revoke/${userId}/`;

    axios.delete(url)
        .then(() => {
            success(userId);
        })
        .catch(err => {
            failure(err);
        });
};
