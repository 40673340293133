import React, {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import {Chip, MenuItem, TextField} from "@mui/material";
import {AttachFile as FileIcon} from "@mui/icons-material";
import {forms} from "@atttomyx/shared-utils";
import {TAGS} from "../../constants";
import "./documentFields.css";

const DocumentFields = (props) => {
    const {data, showTag = true, onChange: onSync} = props;

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length === 1) {
            onChange("file", acceptedFiles[0]);
        }
    }, [data]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: onDrop,
        maxFiles: 1,
    });

    const onChange = (field, value) => {
        // we can't just use objects.deepCopy here because that messes up the file object
        const modified = {
            tag: data.tag,
            file: data.file,
        };

        modified[field] = value;
        modified.valid = modified.tag && modified.file;

        onSync(modified);
    }

    return <div className="document-fields">
        {showTag ?
            <div className="field top-margin">
                <TextField label="Category" required={true} select={true}
                           type="text"
                           value={forms.sanitizeValue(data.tag)}
                           onChange={(event) => onChange("tag", event.target.value)}
                >
                    {Object.entries(TAGS).map(([tag, label]) =>
                        <MenuItem key={tag} value={tag}>
                            {label}
                        </MenuItem>)}
                </TextField>
            </div> : null}
        {data.file ?
            <div className="field">
                <Chip
                    avatar={<FileIcon/>}
                    label={data.file.name}
                    onDelete={() => onChange("file", null)}
                />
            </div> :
            <div {...getRootProps()} className="field dnd-file">
                <input {...getInputProps()} />
                {isDragActive ?
                    <p>Drop the file here</p> :
                    <p>Drag & drop a file here,<br/>or click to select one</p>}
            </div>}
    </div>
}

export default DocumentFields;
