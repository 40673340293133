import axios from 'axios';

export const listTasks = (cursor, limit, success, failure) => {
    let url = `/api/v1/task/list?limit=${limit}`;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const createTask = (task, success, failure) => {
    const url = "/api/v1/task/";

    axios.post(url, {
        userId: task.userId,
        onBehalfOfUserId: task.onBehalfOfUserId,
        title: task.title,
        description: task.description,
        dueOn: task.dueOn,
        dueAt: task.dueAt,
        completed: task.completed,
        type: task.type,
        metadata: task.metadata,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const saveTask = (taskId, task, success, failure) => {
    const url = `/api/v1/task/${taskId}/`;

    axios.put(url, {
        userId: task.userId,
        onBehalfOfUserId: task.onBehalfOfUserId,
        title: task.title,
        description: task.description,
        dueOn: task.dueOn,
        dueAt: task.dueAt,
        completed: task.completed,
        type: task.type,
        metadata: task.metadata,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const completeTask = (taskId, success, failure) => {
    const url = `/api/v1/task/${taskId}/complete`;

    axios.put(url)
        .then(response => {
            const json = response.data;

            success(json.task, json.pdf);
        })
        .catch(err => {
            failure(err);
        });
};

export const deleteTask = (taskId, success, failure) => {
    const url = `/api/v1/task/${taskId}/`;

    axios.delete(url)
        .then(response => {
            success(taskId);
        })
        .catch(err => {
            failure(err);
        });
};
