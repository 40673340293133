import axios from 'axios';
import {SERVICES_BOTH, SERVICES_UPSELL_ANALYSIS} from "../constants";

export const loadWizard = (success, failure) => {
    const url = "/api/v1/wizard/";

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const resetWizard = (success, failure) => {
    const url = "/api/v1/wizard/reset";

    axios.put(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const markWelcome = (success, failure) => {
    const url = "/api/v1/wizard/markWelcome";

    axios.put(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const markContactInfo = (success, failure) => {
    const url = "/api/v1/wizard/markContactInfo";

    axios.put(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const signNda = (signature, success, failure) => {
    const url = "/api/v1/wizard/signNda";

    axios.put(url, {
        signatureImageUrl: signature,
    })
        .then(response => {
            const json = response.data;

            success(json.wizard, json.pdf);
        })
        .catch(err => {
            failure(err);
        });
};

export const inventionQuestionnaire = (data, success, failure) => {
    const url = "/api/v1/wizard/inventionQuestionnaire";

    axios.put(url, {
        title: data.title,
        purpose: data.purpose,
        howToUse: data.howToUse,
        description: data.description,
        uniqueness: data.uniqueness,
        others: data.others,
        prototype: data.prototype,
        patentDate: data.patentDate,
        patentType: data.patentType,
    })
        .then(response => {
            const json = response.data;

            success(json.wizard, json.pdf);
        })
        .catch(err => {
            failure(err);
        });
};

export const purchaseServices = (services, success, failure) => {
    const url = "/api/v1/wizard/purchaseServices";
    const item = services.item;
    const itemToUse = SERVICES_UPSELL_ANALYSIS === item ? SERVICES_BOTH : item;

    axios.put(url, {
        services: itemToUse,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const signSearchAgreement = (signature, data, success, failure) => {
    const url = "/api/v1/wizard/signSearchAgreement";

    axios.put(url, {
        signatureImageUrl: signature,
        firstName: data.firstName,
        lastName: data.lastName,
        address: data.address,
        email: data.email,
        phone1: data.phone1,
        phone2: data.phone2,
        inventors: data.inventors,
        title: data.title,
        purpose: data.purpose,
        howToUse: data.howToUse,
        description: data.description,
        uniqueness: data.uniqueness,
        knowledge: data.knowledge,
        additional: data.additional,
    })
        .then(response => {
            const json = response.data;

            success(json.wizard, json.pdf);
        })
        .catch(err => {
            failure(err);
        });
};

export const signSearchResults = (signature, success, failure) => {
    const url = "/api/v1/wizard/signSearchResults";

    axios.put(url, {
        signatureImageUrl: signature,
    })
        .then(response => {
            const json = response.data;

            success(json.wizard, json.pdf);
        })
        .catch(err => {
            failure(err);
        });
};

export const submitAnalysisQuestionnaire = (data, success, failure) => {
    const url = "/api/v1/wizard/analysisQuestionnaire";

    axios.put(url, {
        patentDate: data.patentDate,
        patentType: data.patentType,
        patentNumber: data.patentNumber,
        prototype: data.prototype,
        inventory: data.inventory,
        website: data.website,
        designs: data.designs,
        expertise: data.expertise,
        others: data.others,
        manufacture: data.manufacture,
        marketing: data.marketing,
        competition: data.competition,
        categories: data.categories,
        price: data.price,
        costToMake: data.costToMake,
        audience: data.audience,
    })
        .then(response => {
            const json = response.data;

            success(json.wizard, json.pdf);
        })
        .catch(err => {
            failure(err);
        });
};

export const signAnalysisResults = (signature, success, failure) => {
    const url = "/api/v1/wizard/signAnalysisResults";

    axios.put(url, {
        signatureImageUrl: signature,
    })
        .then(response => {
            const json = response.data;

            success(json.wizard, json.pdf);
        })
        .catch(err => {
            failure(err);
        });
};

export const submitUpsellQuestionnaire = (data, success, failure) => {
    const url = "/api/v1/wizard/upsellQuestionnaire";

    axios.put(url, {
        patent: data.patent,
        consultation: data.consultation,
        marketing: data.marketing,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const purchaseMarketing = (data, success, failure) => {
    const url = "/api/v1/wizard/purchaseMarketing";

    axios.put(url, {
        images: data.images,
        patent: data.patent,
        bundle: data.assistance ? data.bundle : null,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const markProductIntro = (success, failure) => {
    const url = "/api/v1/wizard/markProductIntro";

    axios.put(url)
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(err => {
        failure(err);
    });
};

export const productImages = (documentIds, success, failure) => {
    const url = "/api/v1/wizard/productImages";

    axios.put(url, {
        documentIds: documentIds,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const productText = (data, success, failure) => {
    const url = "/api/v1/wizard/productText";

    axios.put(url, {
        name: data.name,
        descriptions: data.descriptions,
        features: data.features,
    })
        .then(response => {
            const json = response.data;

            success(json.wizard, json.pdf);
        })
        .catch(err => {
            failure(err);
        });
};

export const markComplete = (success, failure) => {
    const url = "/api/v1/wizard/markComplete";

    axios.put(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};
