import React from 'react';
import {Card, CardContent, Typography} from "@mui/material";
import {money} from "@atttomyx/shared-utils";
import {
    PRICES,
    SERVICES_BOTH,
    SERVICES_PATENT_SEARCH,
    SERVICES_PRODUCT_ANALYSIS,
    SERVICES_SUPREME,
    SERVICES_UPSELL_ANALYSIS
} from "../../constants";
import "./serviceCard.css";

const ServiceCard = (props) => {
    const { type } = props;

    return <Card className={"service-card " + type} raised={true}>
        <CardContent>
            {type === SERVICES_SUPREME ?
                <>
                    <Typography variant="h6">
                        Supreme Package
                    </Typography>
                    <ul>
                        <li>
                            Patent Search
                        </li>
                        <li>
                            Product Analysis & Evaluation
                        </li>
                        <li>
                            3D renditions (for effective marketing)
                        </li>
                        <li>
                            Patent drawings & technical summary of invention (prepared for Patent Attorney or Patent Agent)
                        </li>
                        <li>
                            Video & video posted to YouTube channel
                        </li>
                        <li>
                            Website
                        </li>
                        <li>
                            Business plan & presentation for professionally seeking investment
                        </li>
                        <li>
                            Professional Sell-sheet
                        </li>
                        <li>
                            Proactive Marketing efforts to 25 companies within your product category
                        </li>
                        <li>
                            A valuable trade list of manufacturers for you, the inventor, to self-market to in parallel to our efforts
                        </li>
                    </ul>
                </> : type === SERVICES_PATENT_SEARCH ?
                <>
                    <Typography variant="h6" paragraph={true}>
                        Patent Search
                    </Typography>
                    <Typography className="smaller" paragraph={true}>
                        A patent search will be performed for the purpose of finding the most relevant existing U.S. & Foreign Patents and Publications. This step is crucial to determine if your invention appears to be new, useful, and non-obvious.
                    </Typography>
                    <Typography className="smaller" paragraph={true}>
                        A patent search is often the first thing that is done in the patent process. The purpose of a patent search is to determine how different an invention is from what already exists in the ‘prior art’.  A patent search benefits the inventor by identifying the closest prior filed patents and publications so that a determination can be made of both how patentable the invention appears to be, and also what specific parts of the invention are the most different from the prior art.  A patent search can also reveal that the inventor’s invention has already been made, even if the invention has never been commercially available for purchase.
                    </Typography>
                </> : type === SERVICES_PRODUCT_ANALYSIS || type === SERVICES_UPSELL_ANALYSIS ?
                <>
                    <Typography variant="h6" paragraph={true}>
                        Product Analysis & Evaluation
                    </Typography>
                    <Typography className="smaller" paragraph={true}>
                        The purpose of this evaluation is to give you some valuable feedback as to what strengths and weaknesses your invention may have early in the process to assist you in determining your course of action with your invention before spending any more money.
                    </Typography>
                </> : type === SERVICES_BOTH ?
                <>
                    <Typography variant="h6" paragraph={true}>
                        Patent Search and Product Analysis & Evaluation
                    </Typography>
                    <Typography className="smaller" paragraph={true}>
                        Recommended.
                    </Typography>
                    <Typography className="smaller" paragraph={true}>
                        Most of our clients purchase the Patent Search and Product Analysis & Evaluation package in order to save time and money, and to get the process started as quickly as possible.
                    </Typography>
                </> : null}
            <Typography className="cost">
                <strong>{money.render(PRICES[type])}</strong>
                {type === SERVICES_BOTH ?
                    <Typography variant="caption">
                        &nbsp;(savings of $99.00)
                    </Typography> : type === SERVICES_SUPREME ?
                    <Typography variant="caption">
                        &nbsp;(best value)
                    </Typography> : null}
            </Typography>
        </CardContent>
    </Card>
}

export default ServiceCard;
