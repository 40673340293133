import React, {useState} from "react";
import {Button, Typography} from "@mui/material";
import YourFilesBlurb from "../../components/yourFilesBlurb/yourFilesBlurb";
import SearchDialog from "../../dialogs/searchDialog/searchDialog";

const SearchStep = (props) => {
    const { snackbar, user, invention, onSaveWizard, onSaveDocument } = props;
    const [ showDialog, setShowDialog ] = useState(false);

    return <>
        <Typography>
            Please complete the agreement
        </Typography>
        <YourFilesBlurb/>
        <div className="action">
            <Button
                color="primary"
                onClick={() => setShowDialog(true)}
            >
                Sign Agreement
            </Button>
        </div>
        {showDialog ?
            <SearchDialog
                snackbar={snackbar}
                user={user}
                invention={invention}
                onCancel={() => setShowDialog(false)}
                onSave={(wizard, pdf) => {
                    setShowDialog(false);
                    onSaveWizard(wizard);
                    onSaveDocument(pdf);
                }}
            /> : null}
    </>
};

export default SearchStep;
