import React, {useState} from "react";
import {Typography} from "@mui/material";
import SpinnerButton from "../../components/spinnerButton/spinnerButton";
import * as wizardService from "../../services/wizards";

const ProductIntroStep = (props) => {
    const { snackbar, onSaveWizard } = props;
    const [ saving, setSaving ] = useState(false);

    const next = () => {
        setSaving(true);

        const success = (wizard) => {
            onSaveWizard(wizard);
            setSaving(false);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        }

        wizardService.markProductIntro(success, failure);
    };

    return <>
        <Typography paragraph={true}>
            Let's start creating your one-page sell-sheet!
        </Typography>
        <Typography paragraph={true}>
            A strong sell-sheet should be visually enticing and easy to read. Think of it as the
            physical equivalent of your elevator pitch. This document should concisely detail how
            your product solves a specific problem, and the benefits of your product.
        </Typography>
        <SpinnerButton
            color="primary"
            label="Go"
            onClick={next}
            spinner={saving}
        />
    </>
};

export default ProductIntroStep;
