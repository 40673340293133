import React from "react";
import {Button} from "@mui/material";
import ServicesFields from "../../forms/servicesFields/servicesFields";

const ServicesStoreStep = (props) => {
    const { services, onChange, onCheckout } = props;

    return <>
        <ServicesFields
            data={services}
            onChange={onChange}
        />
        <div className="action">
            <Button
                color="primary"
                onClick={onCheckout}
                disabled={!services.valid}
            >
                Checkout
            </Button>
        </div>
    </>
};

export default ServicesStoreStep;
