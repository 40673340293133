import React from "react";
import {TextField} from "@mui/material";
import {forms, objects, strings} from "@atttomyx/shared-utils";

const ProductTextFields = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = strings.isNotBlank(modified.name)
            && strings.isNotBlank(modified.description0)
            && strings.isNotBlank(modified.feature0);

        onSync(modified);
    };

    const renderDescription = (num) => {
        const key = "description" + num;

        return <div className="field">
            <TextField label={"Description " + (num + 1)} required={num === 0}
                       type="text"
                       value={forms.sanitizeValue(data[key])}
                       onChange={(event) => onChange(key, event.target.value)}
            />
        </div>
    };

    const renderFeature = (num) => {
        const key = "feature" + num;

        return <div className="field">
            <TextField label={"Feature " + (num + 1)} required={num === 0}
                       type="text"
                       value={forms.sanitizeValue(data[key])}
                       onChange={(event) => onChange(key, event.target.value)}
            />
        </div>
    };

    return <div className="product-text-fields">
        <div className="field top-margin">
            <TextField label="Product Name" required={true}
                       type="text"
                       value={forms.sanitizeValue(data.name)}
                       onChange={(event) => onChange("name", event.target.value)}
            />
        </div>
        {renderDescription(0)}
        {renderDescription(1)}
        {renderDescription(2)}
        {renderDescription(3)}
        {renderDescription(4)}
        {renderDescription(5)}
        {renderDescription(6)}
        {renderDescription(7)}
        {renderDescription(8)}
        {renderDescription(9)}
        {renderFeature(0)}
        {renderFeature(1)}
        {renderFeature(2)}
        {renderFeature(3)}
        {renderFeature(4)}
        {renderFeature(5)}
        {renderFeature(6)}
        {renderFeature(7)}
        {renderFeature(8)}
        {renderFeature(9)}
    </div>
}

export default ProductTextFields;
