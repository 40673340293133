import React from "react";
import {TextField} from "@mui/material";
import {BoolFilterField, UserField} from "@atttomyx/react-components";
import {forms, objects, strings} from "@atttomyx/shared-utils";

const TaskFilters = (props) => {
    const { data, onChange : onSync, formProps } = props;
    const { admins, users } = formProps;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);
        modified[field] = value;

        if (field === "assigned" && value === "false") {
            delete modified.userId;

        } else if (field === "userId") {
            if (strings.isNotBlank(value)) {
                modified.assigned = "true";

            } else {
                delete modified.assigned;
            }
        }

        onSync(modified);
    }

    return <div className="task-filters">
        <div className="field">
            <TextField label="Title"
                       type="text"
                       value={forms.sanitizeValue(data.title)}
                       onChange={(event) => onChange("title", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Description"
                       type="text"
                       value={forms.sanitizeValue(data.description)}
                       onChange={(event) => onChange("description", event.target.value)}
            />
        </div>
        <div className="field">
            <UserField
                label="For"
                users={users}
                value={data.onBehalfOfUserId}
                onChange={(userId) => onChange("onBehalfOfUserId", userId)}
            />
        </div>
        {objects.nullOrUndefined(data.assigned) || data.assigned !== "false" ? <div className="field">
            <UserField
                label="Owner"
                users={admins}
                value={data.userId}
                onChange={(userId) => onChange("userId", userId)}
            />
        </div> : null}
        <div className="field">
            <BoolFilterField label="Assigned"
                             value={forms.sanitizeValue(data.assigned)}
                             onChange={(value) => onChange("assigned", value)}
            />
        </div>        <div className="field">
            <BoolFilterField label="Completed"
                             value={forms.sanitizeValue(data.completed)}
                             onChange={(value) => onChange("completed", value)}
            />
        </div>
    </div>
}

export default TaskFilters;
