import {Button, CircularProgress} from "@mui/material";
import React from "react";

const SpinnerButton = (props) => {
    const { spinner, color, label, onClick, disabled } = props;

    return <div className="action">
        {spinner ?
            <CircularProgress size="28px" color={color}/> :
            <Button
                color={color}
                onClick={onClick}
                disabled={disabled}
            >
                {label}
            </Button>}
    </div>
};

export default SpinnerButton;
