import {TAG_OTHER, TAGS} from "../constants";

export const sanitizeTag = (tag) => {
    return Object.hasOwn(TAGS, tag) ? tag : TAG_OTHER;
};

export const renderTag = (tag) => {
    const sanitized = sanitizeTag(tag);

    return TAGS[sanitized];
};
