import React from "react";
import {TextField} from "@mui/material";
import {forms, objects, strings} from "@atttomyx/shared-utils";
import {UserField} from "@atttomyx/react-components";

const TaskFields = (props) => {
    const { admins, data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = strings.isNotBlank(modified.dueOn);

        onSync(modified);
    }

    return <div className="task-fields">
        <div className="field top-margin">
            <TextField label="Due" required={true}
                       type="date"
                       value={forms.sanitizeValue(data.dueOn)}
                       onChange={(event) => onChange("dueOn", event.target.value)}
            />
        </div>
        <div className="field">
            <UserField
                label="Assigned to"
                users={admins}
                value={data.userId}
                onChange={(userId) => onChange("userId", userId)}
            />
        </div>
    </div>
}

export default TaskFields;
