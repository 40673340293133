import React from "react";
import {Checkbox, FormControlLabel, TextField, Typography} from "@mui/material";
import {forms, objects, strings} from "@atttomyx/shared-utils";

const InventionFields = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = strings.isNotBlank(modified.title)
            && strings.isNotBlank(modified.purpose)
            && strings.isNotBlank(modified.howToUse)
            && strings.isNotBlank(modified.description)
            && strings.isNotBlank(modified.uniqueness);

        onSync(modified);
    };

    return <div className="invention-fields">
        <div className="field">
            <TextField label="Title of invention"
                       required={true}
                       type="text"
                       value={forms.sanitizeValue(data.title)}
                       onChange={(event) => onChange("title", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Purpose of invention"
                       required={true}
                       type="text"
                       multiline={true}
                       value={forms.sanitizeValue(data.purpose)}
                       onChange={(event) => onChange("purpose", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="How to use invention"
                       required={true}
                       type="text"
                       multiline={true}
                       value={forms.sanitizeValue(data.howToUse)}
                       onChange={(event) => onChange("howToUse", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Description and physical attributes"
                       required={true}
                       type="text"
                       multiline={true}
                       value={forms.sanitizeValue(data.description)}
                       onChange={(event) => onChange("description", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="What you believe to be new"
                       required={true}
                       type="text"
                       multiline={true}
                       value={forms.sanitizeValue(data.uniqueness)}
                       onChange={(event) => onChange("uniqueness", event.target.value)}
            />
        </div>
        <div className="option">
            <FormControlLabel labelPlacement="end"
                              label="Are there other versions?"
                              control={<Checkbox color="primary"
                                                 checked={forms.sanitizeOption(data.others)}
                                                 onChange={(event) => onChange("others", event.target.checked)}
                              />}
            />
        </div>
        <div className="option">
            <FormControlLabel labelPlacement="end"
                              label="Do you have a prototype?"
                              control={<Checkbox color="primary"
                                                 checked={forms.sanitizeOption(data.prototype)}
                                                 onChange={(event) => onChange("prototype", event.target.checked)}
                              />}
            />
        </div>
        <Typography variant="caption">
            Do you have patent protection?
        </Typography>
        <div className="field">
            <TextField label="Existing patent"
                       type="date"
                       value={forms.sanitizeValue(data.patentDate)}
                       onChange={(event) => onChange("patentDate", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Patent type"
                       type="text"
                       value={forms.sanitizeValue(data.patentType)}
                       onChange={(event) => onChange("patentType", event.target.value)}
            />
        </div>
    </div>
}

export default InventionFields;
