import React, {useState} from 'react';
import fileDownload from "js-file-download";
import {
    Card,
    CardContent,
    CircularProgress,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import * as documentService from "../../services/documents";
import {datetime, numbers} from "@atttomyx/shared-utils";
import {renderTag} from "../../utils/tags";
import "./documentsCard.css";

const DocumentsCard = (props) => {
    const { snackbar, user, userId, tag, documents } = props;
    const [ downloading, setDownloading ] = useState(null);

    const download = (document) => {
        setDownloading(document.id);

        const success = (bytes) => {
            fileDownload(bytes, document.fileName);
            setDownloading(null);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setDownloading(null);
        };

        if (user.roles.admin) {
            documentService.downloadDocument(userId, document.fileName, success, failure);

        } else if (userId && userId !== user.id) {
            documentService.downloadSharedDocument(userId, document.fileName, success, failure);

        } else {
            documentService.downloadMyDocument(document.fileName, success, failure);
        }
    };

    return <Card className="documents-card" raised={true}>
        <CardContent>
            <Typography variant="h4" paragraph={true}>
                {renderTag(tag)}
            </Typography>
            <Table size="small" cellPadding={0} cellSpacing={0} className="striped">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Name
                        </TableCell>
                        <TableCell>
                            Size
                        </TableCell>
                        <TableCell>
                            Created
                        </TableCell>
                        <TableCell>
                            Updated
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {documents.map(document =>
                        <TableRow key={document.id}>
                            <TableCell className="file" title={document.fileName}>
                                {downloading === document.id ?
                                    <CircularProgress size="14px"/> :
                                    <Link onClick={() => download(document)}>
                                        {document.fileName}
                                    </Link>}
                            </TableCell>
                            <TableCell>
                                {numbers.abbreviateNumber(document.size)}
                            </TableCell>
                            <TableCell className="timestamp">
                                {datetime.getPrettyDateAndTime(document.created)}
                            </TableCell>
                            <TableCell className="timestamp">
                                {datetime.getPrettyDateAndTime(document.updated)}
                            </TableCell>
                        </TableRow>)}
                </TableBody>
            </Table>
        </CardContent>
    </Card>
}

export default DocumentsCard;
