import React, {useEffect, useState} from "react";
import {Divider, Step, StepContent, StepLabel, Stepper, Typography} from "@mui/material";
import {useQuery} from "@atttomyx/react-hooks";
import SpinnerButton from "../../components/spinnerButton/spinnerButton";
import StripeDialog from "../../dialogs/stripeDialog/stripeDialog";
import WelcomeStep from "../../steps/welcomeStep/welcomeStep";
import ProfileStep from "../../steps/profileStep/profileStep";
import NdaStep from "../../steps/ndaStep/ndaStep";
import InventionStep from "../../steps/inventionStep/inventionStep";
import ServicesStoreStep from "../../steps/servicesStoreStep/servicesStoreStep";
import SearchStep from "../../steps/searchStep/searchStep";
import SearchResultsStep from "../../steps/searchResultsStep/searchResultsStep";
import UpsellAnalysisStep from "../../steps/upsellAnalysisStep/upsellAnalysisStep";
import AnalysisStep from "../../steps/analysisStep/analysisStep";
import AnalysisResultsStep from "../../steps/analysisResultsStep/analysisResultsStep";
import UpsellMarketingStep from "../../steps/upsellMarketingStep/upsellMarketingStep";
import MarketingStoreStep from "../../steps/marketingStoreStep/marketingStoreStep";
import MarketingThanksStep from "../../steps/marketingThanksStep/marketingThanksStep";
import ProductIntroStep from "../../steps/productIntroStep/productIntroStep";
import ProductImagesStep from "../../steps/productImagesStep/productImagesStep";
import ProductTextStep from "../../steps/productTextStep/productTextStep";
import ProductThanksStep from "../../steps/productThanksStep/productThanksStep";
import DoneStep from "../../steps/doneStep/doneStep";
import ContactUs from "../../components/contactUs/contactUs";
import * as wizardService from "../../services/wizards";
import {arrays, money as moneyUtils} from "@atttomyx/shared-utils";
import {storage} from "@atttomyx/react-utils";
import {
    KEY_STRIPE,
    MARKETING_PATENT_ILLUSTRATIONS,
    MARKETING_PRODUCT_IMAGES,
    PRICES,
    SERVICES_BOTH,
    SERVICES_PATENT_SEARCH,
    SERVICES_PRODUCT_ANALYSIS,
    SERVICES_SUPREME,
    STEP_ANALYSIS_QUESTIONNAIRE,
    STEP_ANALYSIS_RESULTS,
    STEP_ANALYSIS_UPSELLS,
    STEP_BOTH_ANALYSIS_QUESTIONNAIRE,
    STEP_BOTH_ANALYSIS_RESULTS,
    STEP_BOTH_SIGN_SEARCH,
    STEP_BOTH_SIGN_SEARCH_RESULTS,
    STEP_BOTH_UPSELLS,
    STEP_DONE,
    STEP_MARKETING_STORE,
    STEP_MARKETING_THANKS,
    STEP_NULL,
    STEP_PRODUCT_IMAGES,
    STEP_PRODUCT_INTRO,
    STEP_PRODUCT_TEXT,
    STEP_PRODUCT_THANKS,
    STEP_SEARCH_SIGN_AGREEMENT,
    STEP_SEARCH_SIGN_RESULTS,
    STEP_SEARCH_UPSELLS,
    STEP_START_INVENTION,
    STEP_START_PROFILE,
    STEP_START_SIGN_NDA,
    STEP_START_STORE,
    STEP_START_WELCOME,
    STRIPE_PARAM_CLIENT_SECRET
} from "../../constants";
import "./wizardPage.css";

const session = storage.getSession();

const freshServices = () => {
    return {
        item: null,
        valid: false,
    }
};

const freshMarketing = () => {
    return {
        images: false,
        patent: false,
        assistance: false,
        bundle: null,
        valid: false,
    }
};

const freshStripe = () => {
    return {
        services: freshServices(),
        marketing: freshMarketing(),
        clientSecret: null,
        show: false,
    }
};

const WizardPage = (props) => {
    const {snackbar, user, wizard, onSaveProfile, onSaveWizard, onSaveDocument} = props;
    const [step, setStep] = useState(STEP_NULL);
    const [saving, setSaving] = useState(false);
    const [stripe, setStripe] = useState(freshStripe());
    const query = useQuery();

    useEffect(() => {
        if (wizard.complete) {
            setStep(STEP_DONE);

        } else if (wizard.marketing) {
            if (wizard.marketing.bundle || wizard.services === SERVICES_SUPREME) {
                if (wizard.productText) {
                    setStep(STEP_PRODUCT_THANKS);

                } else if (wizard.productImages) {
                    setStep(STEP_PRODUCT_TEXT);

                } else if (wizard.productIntro) {
                    setStep(STEP_PRODUCT_IMAGES);

                } else {
                    setStep(STEP_PRODUCT_INTRO);
                }

            } else {
                setStep(STEP_MARKETING_THANKS);
            }

        } else if (wizard.upsellQuestionnaire) {
            setStep(STEP_MARKETING_STORE);

        } else if (wizard.services) {
            switch (wizard.services) {
                case SERVICES_PATENT_SEARCH:
                    if (wizard.signedSearchResults) {
                        setStep(STEP_SEARCH_UPSELLS);

                    } else if (wizard.signedSearchAgreement) {
                        setStep(STEP_SEARCH_SIGN_RESULTS);

                    } else {
                        setStep(STEP_SEARCH_SIGN_AGREEMENT);
                    }
                    break;
                case SERVICES_PRODUCT_ANALYSIS:
                    if (wizard.signedAnalysisResults) {
                        setStep(STEP_ANALYSIS_UPSELLS);

                    } else if (wizard.analysisQuestionnaire) {
                        setStep(STEP_ANALYSIS_RESULTS);

                    } else {
                        setStep(STEP_ANALYSIS_QUESTIONNAIRE);
                    }
                    break;
                case SERVICES_BOTH:
                case SERVICES_SUPREME:
                    if (wizard.signedAnalysisResults) {
                        setStep(STEP_BOTH_UPSELLS);

                    } else if (wizard.analysisQuestionnaire) {
                        setStep(STEP_BOTH_ANALYSIS_RESULTS);

                    } else if (wizard.signedSearchResults) {
                        setStep(STEP_BOTH_ANALYSIS_QUESTIONNAIRE);

                    } else if (wizard.signedSearchAgreement) {
                        setStep(STEP_BOTH_SIGN_SEARCH_RESULTS);

                    } else {
                        setStep(STEP_BOTH_SIGN_SEARCH);
                    }
                    break;
                default:
                    snackbar.error("Unrecognized purchase");
                    break;
            }

        } else if (wizard.inventionQuestionnaire) {
            setStep(STEP_START_STORE);

        } else if (wizard.signedNda) {
            setStep(STEP_START_INVENTION);

        } else if (wizard.contactInfo) {
            setStep(STEP_START_SIGN_NDA);

        } else if (wizard.welcome) {
            setStep(STEP_START_PROFILE);

        } else {
            setStep(STEP_START_WELCOME);
        }
    }, [wizard]);

    useEffect(() => {
        const clientSecret = query.get(STRIPE_PARAM_CLIENT_SECRET);

        // if we see the param, that means square redirected back to us
        if (clientSecret) {
            const existing = session.getObj(KEY_STRIPE);

            if (existing) {
                setStripe({
                    services: existing.services,
                    marketing: existing.marketing,
                    clientSecret: clientSecret,
                    show: true,
                });
            }
        }
    }, []);

    const failure = (err) => {
        snackbar.setError(err);
        setSaving(false);
    };

    const resetWizard = () => {
        const success = (wizard) => {
            onSaveWizard(wizard);
            setSaving(false);
        };

        setSaving(true);
        wizardService.resetWizard(success, failure);
    };

    const renderStripeDialog = () => {
        const resetStripe = () => {
            setStripe(freshStripe());
            session.clear(KEY_STRIPE);
        };

        const success = (wizard) => {
            resetStripe();
            onSaveWizard(wizard);
            setSaving(false);
        };

        const paid = () => {
            setSaving(true);

            if (stripe.marketing.valid) {
                wizardService.purchaseMarketing(stripe.marketing, success, failure);

            } else if (stripe.services.valid) {
                wizardService.purchaseServices(stripe.services, success, failure);
            }
        };

        // we have to store it in the session because square redirects us away from the page
        session.setStr(KEY_STRIPE, {
            services: stripe.services,
            marketing: stripe.marketing,
        });

        let money = {
            dollars: 0,
            cents: 0,
        };

        if (stripe.marketing.valid) {
            const monies = [];

            if (stripe.marketing.images) {
                arrays.addTo(monies, PRICES[MARKETING_PRODUCT_IMAGES]);
            }

            if (stripe.marketing.patent) {
                arrays.addTo(monies, PRICES[MARKETING_PATENT_ILLUSTRATIONS]);
            }

            if (stripe.marketing.assistance && stripe.marketing.bundle) {
                arrays.addTo(monies, PRICES[stripe.marketing.bundle]);
            }

            money = moneyUtils.add(monies);

        } else if (stripe.services.valid) {
            money = PRICES[stripe.services.item];
        }

        return <StripeDialog
            snackbar={snackbar}
            user={user}
            money={money}
            clientSecret={stripe.clientSecret}
            onCancel={resetStripe}
            onPay={paid}
        />
    };

    const renderStep = (label, children) => {
        return <Step key={label}>
            <StepLabel>
                {label}
            </StepLabel>
            <StepContent>
                {children}
            </StepContent>
        </Step>
    };

    const renderWelcome = () => {
        return <WelcomeStep
            snackbar={snackbar}
            onSaveWizard={onSaveWizard}
        />
    };

    const renderProfile = () => {
        return <ProfileStep
            snackbar={snackbar}
            user={user}
            onSaveWizard={onSaveWizard}
            onSaveProfile={onSaveProfile}
        />
    };

    const renderNda = () => {
        return <NdaStep
            snackbar={snackbar}
            user={user}
            onSaveWizard={onSaveWizard}
            onSaveDocument={onSaveDocument}
        />
    };

    const renderInvention = () => {
        return <InventionStep
            snackbar={snackbar}
            onSaveWizard={onSaveWizard}
            onSaveDocument={onSaveDocument}
        />
    };

    const renderServicesStore = () => {
        return <ServicesStoreStep
            services={stripe.services}
            onChange={(services) => setStripe({
                services: services,
                marketing: freshMarketing(),
                clientSecret: null,
                show: false,
            })}
            onCheckout={() => setStripe({
                services: stripe.services,
                marketing: freshMarketing(),
                clientSecret: null,
                show: true,
            })}
        />
    };

    const renderSearch = () => {
        return <SearchStep
            snackbar={snackbar}
            user={user}
            invention={wizard.inventionQuestionnaire}
            onSaveWizard={onSaveWizard}
            onSaveDocument={onSaveDocument}
        />
    };

    const renderSearchResults = () => {
        return <SearchResultsStep
            snackbar={snackbar}
            user={user}
            onSaveWizard={onSaveWizard}
            onSaveDocument={onSaveDocument}
        />
    };

    const renderUpsellAnalysis = () => {
        return <UpsellAnalysisStep
            snackbar={snackbar}
            services={stripe.services}
            onChange={(services) => setStripe({
                services: services,
                marketing: freshMarketing(),
                clientSecret: null,
                show: false,
            })}
            onCheckout={() => setStripe({
                services: stripe.services,
                marketing: freshMarketing(),
                clientSecret: null,
                show: true,
            })}
            onSaveWizard={onSaveWizard}
        />
    };

    const renderAnalysis = () => {
        return <AnalysisStep
            snackbar={snackbar}
            user={user}
            invention={wizard.inventionQuestionnaire}
            onSaveWizard={onSaveWizard}
            onSaveDocument={onSaveDocument}
        />
    };

    const renderAnalysisResults = () => {
        return <AnalysisResultsStep
            snackbar={snackbar}
            user={user}
            onSaveWizard={onSaveWizard}
            onSaveDocument={onSaveDocument}
        />
    };

    const renderUpsellMarketing = () => {
        return <UpsellMarketingStep
            snackbar={snackbar}
            supreme={wizard.services === SERVICES_SUPREME}
            onSaveWizard={onSaveWizard}
        />
    };

    const renderMarketingStore = () => {
        return <MarketingStoreStep
            marketing={stripe.marketing}
            onChange={(marketing) => setStripe({
                services: freshServices(),
                marketing: marketing,
                clientSecret: null,
                show: false,
            })}
            onCheckout={() => setStripe({
                services: freshServices(),
                marketing: stripe.marketing,
                clientSecret: null,
                show: true,
            })}
        />
    };

    const renderMarketingThanks = () => {
        return <MarketingThanksStep
            snackbar={snackbar}
            marketing={wizard.marketing}
            onSaveWizard={onSaveWizard}
        />
    };

    const renderProductIntro = () => {
        return <ProductIntroStep
            snackbar={snackbar}
            onSaveWizard={onSaveWizard}
        />
    };

    const renderProductImages = () => {
        return <ProductImagesStep
            snackbar={snackbar}
            user={user}
            onSaveWizard={onSaveWizard}
            onSaveDocument={onSaveDocument}
        />
    };

    const renderProductText = () => {
        return <ProductTextStep
            snackbar={snackbar}
            onSaveWizard={onSaveWizard}
            onSaveDocument={onSaveDocument}
        />
    };

    const renderProductThanks = () => {
        return <ProductThanksStep
            snackbar={snackbar}
            onSaveWizard={onSaveWizard}
        />
    };

    const renderDone = () => {
        return <DoneStep/>
    };

    return <div className="wizard-page">
        {step === STEP_DONE ?
            renderDone() :
            <>
                {wizard.marketing && (wizard.marketing.bundle || wizard.services === SERVICES_SUPREME) ?
                    <Typography variant="h5" paragraph={true}>
                        Sell-Sheet
                    </Typography> : wizard.marketing || wizard.upsellQuestionnaire ?
                    <Typography variant="h5" paragraph={true}>
                        Marketing
                    </Typography> : wizard.services === SERVICES_PATENT_SEARCH ?
                    <Typography variant="h5" paragraph={true}>
                        Patent Search
                    </Typography> : wizard.services === SERVICES_PRODUCT_ANALYSIS ?
                    <Typography variant="h5" paragraph={true}>
                        Product Analysis & Evaluation
                    </Typography> : wizard.services === SERVICES_BOTH || wizard.services === SERVICES_SUPREME ?
                    <Typography variant="h5" paragraph={true}>
                        Patent Search & Product Analysis & Evaluation
                    </Typography> :
                    <Typography variant="h5" paragraph={true}>
                        Getting Started
                    </Typography>}
                {wizard.marketing && (wizard.marketing.bundle || wizard.services === SERVICES_SUPREME) ?
                    <Stepper activeStep={step} orientation="vertical">
                        {renderStep("Intro", renderProductIntro())}
                        {renderStep("Product images", renderProductImages())}
                        {renderStep("Product text", renderProductText())}
                        {renderStep("What's next?", renderProductThanks())}
                    </Stepper> : wizard.marketing || wizard.upsellQuestionnaire ?
                    <Stepper activeStep={step} orientation="vertical">
                        {renderStep("Store", renderMarketingStore())}
                        {renderStep("What's next?", renderMarketingThanks())}
                    </Stepper> : wizard.services === SERVICES_PATENT_SEARCH ?
                    <Stepper activeStep={step} orientation="vertical">
                        {renderStep("Sign search agreement", renderSearch())}
                        {renderStep("Sign search results", renderSearchResults())}
                        {renderStep("What's next?", renderUpsellAnalysis())}
                    </Stepper> : wizard.services === SERVICES_PRODUCT_ANALYSIS ?
                    <Stepper activeStep={step} orientation="vertical">
                        {renderStep("Analysis questionnaire", renderAnalysis())}
                        {renderStep("Sign analysis results", renderAnalysisResults())}
                        {renderStep("What's next?", renderUpsellMarketing())}
                    </Stepper> : wizard.services === SERVICES_BOTH || wizard.services === SERVICES_SUPREME ?
                    <Stepper activeStep={step} orientation="vertical">
                        {renderStep("Sign search agreement", renderSearch())}
                        {renderStep("Sign search results", renderSearchResults())}
                        {renderStep("Analysis questionnaire", renderAnalysis())}
                        {renderStep("Sign analysis results", renderAnalysisResults())}
                        {renderStep("What's next?", renderUpsellMarketing())}
                    </Stepper> :
                    <Stepper activeStep={step} orientation="vertical">
                        {renderStep("Welcome", renderWelcome())}
                        {renderStep("Enter contact info", renderProfile())}
                        {renderStep("Mutual NDA sign & agree", renderNda())}
                        {renderStep("Enter invention info", renderInvention())}
                        {renderStep("Choose your services", renderServicesStore())}
                    </Stepper>}
                {stripe.show ? renderStripeDialog() : null}
            </>}
        <Divider/>
        <ContactUs/>
        {user.settings.test ? <>
            <Divider/>
            <SpinnerButton
                color="secondary"
                label="Reset"
                onClick={resetWizard}
                spinner={saving}
            />
        </> : null}
    </div>
};

export default WizardPage;
