import axios from 'axios';

export const init = (money, success, failure) => {
    const url = "/api/v1/stripe/init";

    axios.post(url, {
        dollars: money.dollars,
        cents: money.cents,
    })
        .then(response => {
            const json = response.data;

            success(json.clientSecret);
        })
        .catch(err => {
            failure(err);
        });
};
