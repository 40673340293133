import React from "react";
import {TextField} from "@mui/material";
import {forms, objects, strings, validation} from "@atttomyx/shared-utils";

const SharingFields = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = strings.isNotBlank(modified.email) && validation.validEmail(modified.email);

        onSync(modified);
    }

    return <div className="sharing-fields">
        <div className="field top-margin">
            <TextField label="Email" required={true}
                       type="email"
                       value={forms.sanitizeValue(data.email)}
                       error={!validation.validEmail(data.email)}
                       onChange={(event) => onChange("email", event.target.value)}
            />
        </div>
    </div>
}

export default SharingFields;
