import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Asterisk, ClosableDialogTitle} from "@atttomyx/react-components";
import AnalysisFields from "../../forms/analysisFields/analysisFields";
import * as wizardService from "../../services/wizards";
import {objects} from "@atttomyx/shared-utils";
import "./analysisDialog.css";

const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: "900px",
    },
}));

const toData = (invention) => {
    return {
        patentDate: invention.patentDate,
        patentType: invention.patentType,
        patentNumber: null,
        prototype: objects.defaultIfNullOrUndefined(invention.prototype, false),
        inventory: false,
        website: null,
        designs: false,
        expertise: null,
        others: objects.defaultIfNullOrUndefined(invention.others, false),
        manufacture: false,
        marketing: null,
        competition: null,
        categories: null,
        price: {
            dollars: 0,
            cents: 0,
            valid: true,
        },
        costToMake: {
            dollars: 0,
            cents: 0,
            valid: true,
        },
        audience: null,
        valid: false,
    }
};

const AnalysisDialog = (props) => {
    const {snackbar, invention, onCancel, onSave} = props;
    const [data, setData] = useState(toData(invention));
    const [saving, setSaving] = useState(false);

    const submitForm = () => {
        setSaving(true);

        const success = (wizard, pdf) => {
            snackbar.setSuccess("Questionnaire submitted");
            setSaving(false);
            onSave(wizard, pdf);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        wizardService.submitAnalysisQuestionnaire(data, success, failure);
    };

    return <Dialog open={true} classes={useStyles()}>
        <ClosableDialogTitle onClose={onCancel}>
            Product Analysis & Evaluation Questionnaire
        </ClosableDialogTitle>
        <DialogContent className="analysis-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <>
                    <Typography>
                        The following questions will assist our team with completing the Product Analysis & Evaluation.
                    </Typography>
                    <Typography paragraph={true}>
                        Please answer all questions and provide as much detail as possible.
                    </Typography>
                    <AnalysisFields
                        data={data}
                        onChange={setData}
                    />
                </>}
        </DialogContent>
        {!saving ? <DialogActions style={{
            marginTop: "10px",
        }}>
            <Asterisk/>
            <Button color="primary"
                    disabled={!data.valid}
                    onClick={submitForm}>
                Submit
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default AnalysisDialog;
