import React, {useEffect, useState} from "react";
import {Prompt} from "react-router-dom";
import {Button, CircularProgress, Divider, Typography} from "@mui/material";
import {TaskAlt as CompleteIcon} from "@mui/icons-material";
import {Fireworks} from "@fireworks-js/react";
import {VerticalDivider} from "@atttomyx/react-components";
import SearchAgreementCard from "../../cards/searchAgreementCard/searchAgreementCard";
import PatentSearchFields from "../../forms/patentSearchFields/patentSearchFields";
import FoundPatentFields from "../../forms/foundPatentFields/foundPatentFields";
import * as taskService from "../../services/tasks";
import {arrays, forms, objects, strings} from "@atttomyx/shared-utils";
import {verbiage} from "@atttomyx/shared-constants";
import {FIREWORKS_OPTIONS, FIREWORKS_STYLE} from "../../constants";
import "./taskPage.css";

const toData = (task) => {
    const metadata = task.metadata || {};
    const agreement = metadata.searchAgreement || {};
    const results = metadata.searchResults || {};
    const id = results.id;
    const description = results.description || agreement.description;
    const class1 = results.class1;
    const class2 = results.class2;

    return {
        id: id,
        description: description,
        class1: class1,
        class2: class2,
        valid: strings.isNotBlank(id)
            && strings.isNotBlank(description)
            && strings.isNotBlank(class1)
            && strings.isNotBlank(class2)
    }
};

const toPatent = (num, task) => {
    const metadata = task.metadata || {};
    const results = metadata.searchResults || {};
    const patents = results.patents || [];
    const patent = patents.length >= num ? patents[num - 1] : {};
    const id = patent.id;
    const citation = patent.citation;
    const documentId = patent.documentId;

    return {
        id: id,
        citation: citation,
        documentId: documentId,
        valid: strings.isNotBlank(id)
            && strings.isNotBlank(citation)
            && strings.isNotBlank(documentId)
    }
};

const TaskPage = (props) => {
    const {snackbar, match, user, tasks, documentCache, onSave} = props;
    const [task, setTask] = useState({});
    const [data, setData] = useState(toData({}));
    const [patent1, setPatent1] = useState(toPatent(1, {}));
    const [patent2, setPatent2] = useState(toPatent(2, {}));
    const [patent3, setPatent3] = useState(toPatent(3, {}));
    const [patent4, setPatent4] = useState(toPatent(4, {}));
    const [patent5, setPatent5] = useState(toPatent(5, {}));
    const [patent6, setPatent6] = useState(toPatent(6, {}));
    const [saving, setSaving] = useState(false);

    const loadTask = (taskId) => {
        const found = arrays.findEntity(tasks, taskId) || {};

        setTask(found);
    };

    useEffect(() => {
        const taskId = match.params.id;

        loadTask(taskId);
    }, []);

    useEffect(() => {
        resetForm();
    }, [task]);

    const resetForm = () => {
        setData(toData(task));
        setPatent1(toPatent(1, task));
        setPatent2(toPatent(2, task));
        setPatent3(toPatent(3, task));
        setPatent4(toPatent(4, task));
        setPatent5(toPatent(5, task));
        setPatent6(toPatent(6, task));
    };

    const saveTask = () => {
        setSaving(true);

        const modified = objects.deepCopy(task);
        modified.metadata.searchResults = {
            id: data.id,
            description: data.description,
            class1: data.class1,
            class2: data.class2,
            patents: [
                forms.rawData(patent1),
                forms.rawData(patent2),
                forms.rawData(patent3),
                forms.rawData(patent4),
                forms.rawData(patent5),
                forms.rawData(patent6),
            ],
        };

        const success = (task) => {
            setTask(task);
            setSaving(false);

            snackbar.setSuccess("Task saved");
            onSave(task);
        };

        const failure = (err) => {
            setSaving(false);

            snackbar.setError("Unable to save task");
        };

        taskService.saveTask(task.id, modified, success, failure);
    };

    const completeTask = () => {
        setSaving(true);

        const userId = task.onBehalfOfUserId;

        const success = (task, pdf) => {
            setTask(task);
            setSaving(false);

            snackbar.setSuccess("Task completed!");
            onSave(task);
            documentCache.onEntitySaved(userId, pdf);
        };

        const failure = (err) => {
            setSaving(false);

            snackbar.setError("Unable to complete task");
        };

        taskService.completeTask(task.id, success, failure);
    };

    const renderPatentCard = (num, value, setter) => {
        const userId = task.onBehalfOfUserId;

        return <div className="card">
            <Typography variant="h5" paragraph={true}>
                Patent {num}
            </Typography>
            <FoundPatentFields
                snackbar={snackbar}
                documentCache={documentCache}
                user={user}
                userId={userId}
                num={num}
                data={value}
                onChange={setter}
            />
        </div>
    };

    const modified = forms.differ(toData(task), data)
        || forms.differ(toPatent(1, task), patent1)
        || forms.differ(toPatent(2, task), patent2)
        || forms.differ(toPatent(3, task), patent3)
        || forms.differ(toPatent(4, task), patent4)
        || forms.differ(toPatent(5, task), patent5)
        || forms.differ(toPatent(6, task), patent6);
    const valid = data.valid
        && patent1.valid && patent2.valid && patent3.valid && patent4.valid && patent5.valid && patent6.valid;

    return saving ?
        <div className="task-page">
            <CircularProgress size="80px"/>
        </div> :
        <div className="task-page">
            <Typography variant="h6" paragraph={true}>
                {task.title || "Task"}
            </Typography>
            <Divider/>
            <div className="cards">
                <SearchAgreementCard task={task}/>
                {!task.completed ?
                    <>
                        <div className="card">
                            <Typography variant="h5" paragraph={true}>
                                General
                            </Typography>
                            <PatentSearchFields
                                data={data}
                                onChange={setData}
                            />
                        </div>
                        {renderPatentCard(1, patent1, setPatent1)}
                        {renderPatentCard(2, patent2, setPatent2)}
                        {renderPatentCard(3, patent3, setPatent3)}
                        {renderPatentCard(4, patent4, setPatent4)}
                        {renderPatentCard(5, patent5, setPatent5)}
                        {renderPatentCard(6, patent6, setPatent6)}
                    </> : <>
                        <Typography variant="h5" className="done">
                            Completed!
                        </Typography>
                        <Fireworks
                            options={FIREWORKS_OPTIONS}
                            style={FIREWORKS_STYLE}
                        />
                    </>}
            </div>
            <div className="actions">
                <Button color="secondary" variant="text"
                        disabled={!modified || task.completed}
                        onClick={resetForm}>
                    Undo
                </Button>
                <Button color="primary" size="large"
                        disabled={!modified || task.completed}
                        onClick={saveTask}>
                    Save
                </Button>
                <VerticalDivider/>
                <Button color="primary" className="complete"
                        disabled={!valid || modified || task.completed}
                        onClick={completeTask}>
                    <CompleteIcon/>Complete
                </Button>
            </div>
            <Prompt when={modified} message={verbiage.UNSAVED_CHANGES}/>
        </div>
}

export default TaskPage;
