import React, {useState} from "react";
import {Typography} from "@mui/material";
import SpinnerButton from "../../components/spinnerButton/spinnerButton";
import UpsellFields from "../../forms/upsellFields/upsellFields";
import * as wizardService from "../../services/wizards";

const UpsellMarketingStep = (props) => {
    const { snackbar, supreme, onSaveWizard } = props;
    const [ saving, setSaving ] = useState(false);
    const [ upsells, setUpsells ] = useState({
        patent: false,
        consultation: false,
        marketing: false,
        valid: true,
    });

    const submit = () => {
        setSaving(true);

        const success = (wizard) => {
            snackbar.setSuccess("Response submitted");
            setSaving(false);
            onSaveWizard(wizard);
        }

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        }

        wizardService.submitUpsellQuestionnaire(upsells, success, failure);
    };

    return <>
        <Typography paragraph={true}>
            Please answer the following questions to help us better assist you with your needs:
        </Typography>
        <UpsellFields
            data={upsells}
            supreme={supreme}
            onChange={setUpsells}
        />
        <SpinnerButton
            color="primary"
            label="Submit"
            onClick={submit}
            spinner={saving}
        />
    </>
};

export default UpsellMarketingStep;
