import React, {useState} from "react";
import {Button, Typography} from "@mui/material";
import YourFilesBlurb from "../../components/yourFilesBlurb/yourFilesBlurb";
import AnalysisDialog from "../../dialogs/analysisDialog/analysisDialog";

const AnalysisStep = (props) => {
    const { snackbar, user, invention, onSaveWizard, onSaveDocument } = props;
    const [ showDialog, setShowDialog ] = useState(false);

    return <>
        <Typography>
            Please complete the questionnaire
        </Typography>
        <YourFilesBlurb/>
        <div className="action">
            <Button
                color="primary"
                onClick={() => setShowDialog(true)}
            >
                Answer Questionnaire
            </Button>
        </div>
        {showDialog ?
            <AnalysisDialog
                snackbar={snackbar}
                user={user}
                invention={invention}
                onCancel={() => setShowDialog(false)}
                onSave={(wizard, pdf) => {
                    setShowDialog(false);
                    onSaveWizard(wizard);
                    onSaveDocument(pdf);
                }}
            /> : null}
    </>
};

export default AnalysisStep;
