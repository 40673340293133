import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Asterisk, ClosableDialogTitle} from "@atttomyx/react-components";
import ProductTextFields from "../../forms/productTextFields/productTextFields";
import * as wizardService from "../../services/wizards";
import {arrays, strings} from "@atttomyx/shared-utils";
import "./productTextDialog.css";

const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: "580px",
    },
}));

const freshData = () => {
    return {
        name: null,
        description0: null,
        description1: null,
        description2: null,
        description3: null,
        description4: null,
        description5: null,
        description6: null,
        description7: null,
        description8: null,
        description9: null,
        feature0: null,
        feature1: null,
        feature2: null,
        feature3: null,
        feature4: null,
        feature5: null,
        feature6: null,
        feature7: null,
        feature8: null,
        feature9: null,
        valid: false,
    };
};

const addToIfNotBlank = (list, data, prefix, num) => {
    const key = prefix + num;
    const item = data[key];

    if (strings.isNotBlank(item)) {
        arrays.addTo(list, item);
    }
};

const normalizeList = (data, prefix) => {
    const list = [];

    addToIfNotBlank(list, data, prefix, 0);
    addToIfNotBlank(list, data, prefix, 1);
    addToIfNotBlank(list, data, prefix, 2);
    addToIfNotBlank(list, data, prefix, 3);
    addToIfNotBlank(list, data, prefix, 4);
    addToIfNotBlank(list, data, prefix, 5);
    addToIfNotBlank(list, data, prefix, 6);
    addToIfNotBlank(list, data, prefix, 7);
    addToIfNotBlank(list, data, prefix, 8);
    addToIfNotBlank(list, data, prefix, 9);

    return list;
};

const normalizeData = (data) => {
    return {
        name: data.name,
        descriptions: normalizeList(data, "description"),
        features: normalizeList(data, "feature"),
    };
};

const ProductTextDialog = (props) => {
    const { snackbar, onCancel, onSave } = props;
    const [ data, setData ] = useState(freshData());
    const [ saving, setSaving ] = useState(false);

    const submitForm = () => {
        setSaving(true);

        const success = (wizard, pdf) => {
            setSaving(false);
            snackbar.setSuccess("Product text saved");
            onSave(wizard, pdf);
        };

        const failure = (err) => {
            setSaving(false);
            snackbar.setError(err);
        };

        const normalized = normalizeData(data);

        wizardService.productText(normalized, success, failure);
    }

    return <Dialog open={true} classes={useStyles()}>
        <ClosableDialogTitle onClose={onCancel}>
            Product Text
        </ClosableDialogTitle>
        <DialogContent className="product-text-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <ProductTextFields
                    data={data}
                    onChange={setData}
                />}
        </DialogContent>
        {!saving ? <DialogActions style={{
            marginTop: "10px",
        }}>
            <Asterisk/>
            <Button color="primary"
                    disabled={!data.valid}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default ProductTextDialog;
