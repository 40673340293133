import React from "react";
import {Checkbox, FormControlLabel, TextField, Typography} from "@mui/material";
import MoneyFields from "../moneyFields/moneyFields";
import {forms, objects, strings} from "@atttomyx/shared-utils";

const AnalysisFields = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = strings.isNotBlank(modified.expertise)
            && strings.isNotBlank(modified.marketing)
            && strings.isNotBlank(modified.competition)
            && strings.isNotBlank(modified.categories)
            && strings.isNotBlank(modified.audience)
            && modified.price.valid
            && modified.costToMake.valid;

        onSync(modified);
    };

    return <div className="analysis-fields">
        <Typography variant="caption">
            Do you have patent protection?
        </Typography>
        <div className="field">
            <TextField label="Patent date"
                       type="date"
                       value={forms.sanitizeValue(data.patentDate)}
                       onChange={(event) => onChange("patentDate", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Patent type"
                       type="text"
                       value={forms.sanitizeValue(data.patentType)}
                       onChange={(event) => onChange("patentType", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Patent number"
                       type="text"
                       value={forms.sanitizeValue(data.patentNumber)}
                       onChange={(event) => onChange("patentNumber", event.target.value)}
            />
        </div>
        <div className="option">
            <FormControlLabel
                labelPlacement="end"
                label="Do you have a working prototype or samples?"
                control={<Checkbox
                    color="primary"
                    checked={forms.sanitizeOption(data.prototype)}
                    onChange={(event) => onChange("prototype", event.target.checked)}
                />}
            />
        </div>
        <div className="option">
            <FormControlLabel
                labelPlacement="end"
                label="Do you have inventory in stock?"
                control={<Checkbox
                    color="primary"
                    checked={forms.sanitizeOption(data.inventory)}
                    onChange={(event) => onChange("inventory", event.target.checked)}
                />}
            />
        </div>
        <div className="field">
            <TextField label="Do you have a website for your invention?"
                       type="text"
                       value={forms.sanitizeValue(data.website)}
                       onChange={(event) => onChange("website", event.target.value)}
            />
        </div>
        <div className="option">
            <FormControlLabel
                labelPlacement="end"
                label="Do you have any professional design work, images, 3D prototype?"
                control={<Checkbox
                    color="primary"
                    checked={forms.sanitizeOption(data.designs)}
                    onChange={(event) => onChange("designs", event.target.checked)}
                />}
            />
        </div>
        <div className="field">
            <TextField label="What is your field of experience or expertise for your invention?"
                       required={true}
                       type="text"
                       value={forms.sanitizeValue(data.expertise)}
                       onChange={(event) => onChange("expertise", event.target.value)}
            />
        </div>
        <div className="option">
            <FormControlLabel
                labelPlacement="end"
                label="Is your invention an improvement on an existing product?"
                control={<Checkbox
                    color="primary"
                    checked={forms.sanitizeOption(data.others)}
                    onChange={(event) => onChange("others", event.target.checked)}
                />}
            />
        </div>
        <div className="option">
            <FormControlLabel
                labelPlacement="end"
                label="Do you intend on manufacturing your invention?"
                control={<Checkbox
                    color="primary"
                    checked={forms.sanitizeOption(data.manufacture)}
                    onChange={(event) => onChange("manufacture", event.target.checked)}
                />}
            />
        </div>
        <div className="field">
            <TextField label="What efforts have you put forth into marketing thus far?"
                       required={true}
                       type="text"
                       value={forms.sanitizeValue(data.marketing)}
                       onChange={(event) => onChange("marketing", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Who are the market competitors for your invention?"
                       required={true}
                       type="text"
                       value={forms.sanitizeValue(data.competition)}
                       onChange={(event) => onChange("competition", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="What are the top 3 marketing categories you feel your product fits into best?"
                       required={true}
                       type="text"
                       value={forms.sanitizeValue(data.categories)}
                       onChange={(event) => onChange("categories", event.target.value)}
            />
        </div>
        <div className="field">
            <MoneyFields
                label="What would be your retail price point?"
                data={data.price}
                onChange={(money) => onChange("price", money)}
            />
        </div>
        <div className="field">
            <MoneyFields
                label="How much would your product cost to make?"
                data={data.costToMake}
                onChange={(money) => onChange("costToMake", money)}
            />
        </div>
        <div className="field">
            <TextField label="Who is the target audience or end-user for your product?"
                       required={true}
                       type="text"
                       value={forms.sanitizeValue(data.audience)}
                       onChange={(event) => onChange("audience", event.target.value)}
            />
        </div>
    </div>
}

export default AnalysisFields;
