import React, {useEffect, useState} from "react";
import {Button, CircularProgress, Dialog, DialogContent} from "@mui/material";
import {loadStripe} from "@stripe/stripe-js";
import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import * as stripeService from "../../services/stripe";
import {money as moneyUtils} from "@atttomyx/shared-utils";
import {
    PAGE_WIZARD,
    STRIPE_ERROR_VALIDATION,
    STRIPE_KEY,
    STRIPE_STATUS_PAYMENT_FAILED,
    STRIPE_STATUS_PROCESSING,
    STRIPE_STATUS_SUCCESS,
    WEB_BASE_URL
} from "../../constants";
import "./stripeDialog.css";

const stripePromise = loadStripe(STRIPE_KEY);

const CheckoutForm = (props) => {
    const { snackbar, clientSecret, onPay } = props;
    const [ saving, setSaving ] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (stripe && clientSecret) {
            stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
                switch (paymentIntent.status) {
                    case STRIPE_STATUS_SUCCESS:
                        snackbar.setSuccess("Payment succeeded!");
                        onPay();
                        break;
                    case STRIPE_STATUS_PROCESSING:
                        snackbar.setWarning("Your payment is processing.");
                        break;
                    case STRIPE_STATUS_PAYMENT_FAILED:
                        snackbar.setError("Your payment was not successful, please try again.");
                        break;
                    default:
                        snackbar.setError("Something went wrong.");
                        break;
                }
            });
        }
    }, [stripe, clientSecret]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (stripe && elements) {
            setSaving(true);

            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${WEB_BASE_URL}/${PAGE_WIZARD}`,
                },
            });

            if (error) {
                if (error.type === STRIPE_ERROR_VALIDATION) {
                    snackbar.setWarning(error.message);

                } else {
                    snackbar.setError(error.message);
                }

                setSaving(false);
            }
        }
    };

    return <form id="checkout-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <div className="action">
            <Button color="primary" type="submit"
                    disabled={saving || !stripe || !elements}>
                Pay
            </Button>
        </div>
    </form>
};

const DocumentDialog = (props) => {
    const { snackbar, user, money, clientSecret, onCancel, onPay } = props;
    const [ stripeOptions, setStripeOptions ] = useState(null);

    useEffect(() => {
        const success = (clientSecret) => {
            setStripeOptions({
                clientSecret: clientSecret,
            });
        };

        const failure = (err) => {
            snackbar.setError(err);
            onCancel();
        }

        if (user.settings.test) {
            snackbar.setSuccess("Test mode - skipped payment of " + moneyUtils.render(money));
            onPay();

        } else if (clientSecret) {
            setStripeOptions({
                clientSecret: clientSecret,
            });

        } else {
            stripeService.init(money, success, failure);
        }
    }, []);

    return <Dialog
        open={true}
        aria-labelledby="stripe-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Checkout - {moneyUtils.render(money)}
        </ClosableDialogTitle>
        <DialogContent className="stripe-dialog">
            {!stripePromise || !stripeOptions ?
                <CircularProgress size="40px"/> :
                <Elements stripe={stripePromise} options={stripeOptions}>
                    <CheckoutForm
                        snackbar={snackbar}
                        clientSecret={clientSecret}
                        onPay={onPay}
                    />
                </Elements>
            }
        </DialogContent>
    </Dialog>
};

export default DocumentDialog;
