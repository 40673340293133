import React from "react";
import {withRouter} from "react-router-dom";
import {Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {
    CheckBoxOutlined as OptionsIcon,
    Face as ProfileIcon,
    Fingerprint as PasswordIcon,
    Info as AboutIcon,
    Logout as LogoutIcon,
    People as UsersIcon,
} from "@mui/icons-material";
import {confirm, mobile, router} from "@atttomyx/react-utils";
import {PAGE_ABOUT, PAGE_OPTIONS, PAGE_PASSWORD, PAGE_PROFILE, PAGE_USERS} from "../../constants";
import "./moreMenu.css";

const MoreMenu = (props) => {
    const { history, user, onCancel, onLogout } = props;

    return <Drawer anchor="right"
                   open={true}
                   onClick={onCancel}>
        <List>
            {user.roles.admin ?
                <>
                    <ListItemButton onClick={() => router.redirectTo(history, PAGE_USERS)}>
                        <ListItemIcon><UsersIcon/></ListItemIcon>
                        <ListItemText>Users</ListItemText>
                    </ListItemButton>
                    <Divider/>
                </> : null}
            <ListItemButton onClick={() => router.redirectTo(history, PAGE_PROFILE)}>
                <ListItemIcon><ProfileIcon/></ListItemIcon>
                <ListItemText>Profile</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={() => router.redirectTo(history, PAGE_OPTIONS)}>
                <ListItemIcon><OptionsIcon/></ListItemIcon>
                <ListItemText>Options</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={() => router.redirectTo(history, PAGE_PASSWORD)}>
                <ListItemIcon><PasswordIcon/></ListItemIcon>
                <ListItemText>Password</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={() => confirm.confirm(mobile.isMobile()
                ? "Someone would need to have physical access to your phone to access this account. Are you sure you want to logout?"
                : "Are you sure you want to logout?", onLogout)}>
                <ListItemIcon><LogoutIcon/></ListItemIcon>
                <ListItemText>Logout</ListItemText>
            </ListItemButton>
            <Divider/>
            <ListItemButton onClick={() => router.redirectTo(history, PAGE_ABOUT)}>
                <ListItemIcon><AboutIcon/></ListItemIcon>
                <ListItemText>About</ListItemText>
            </ListItemButton>
        </List>
    </Drawer>
};

export default withRouter(MoreMenu);
