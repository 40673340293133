import React from "react";
import {TextField} from "@mui/material";
import {forms, objects, strings} from "@atttomyx/shared-utils";

const PatentSearchFields = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = strings.isNotBlank(modified.id)
            && strings.isNotBlank(modified.description)
            && strings.isNotBlank(modified.class1)
            && strings.isNotBlank(modified.class2);

        onSync(modified);
    }

    return <div className="patent-search-fields">
        <div className="field top-margin">
            <TextField label="File number"
                       type="text"
                       value={forms.sanitizeValue(data.id)}
                       onChange={(event) => onChange("id", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Invention description"
                       type="text"
                       multiline={true}
                       value={forms.sanitizeValue(data.description)}
                       onChange={(event) => onChange("description", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Class 1"
                       type="text"
                       value={forms.sanitizeValue(data.class1)}
                       onChange={(event) => onChange("class1", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Class 2"
                       type="text"
                       value={forms.sanitizeValue(data.class2)}
                       onChange={(event) => onChange("class2", event.target.value)}
            />
        </div>
    </div>
}

export default PatentSearchFields;
