import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent} from "@mui/material";
import {Asterisk, ClosableDialogTitle} from "@atttomyx/react-components";
import DocumentFields from "../../forms/documentFields/documentFields";
import * as documentService from "../../services/documents";
import {forms} from "@atttomyx/shared-utils";

const toData = (tag) => {
    return {
        file: null,
        tag: tag,
        valid: false,
    }
};

const DocumentDialog = (props) => {
    const { snackbar, user, userId, tag, onCancel, onUpload } = props;
    const [ data, setData ] = useState(toData(tag));
    const [ saving, setSaving ] = useState(false);

    const submitForm = () => {
        setSaving(true);

        const success = (saved) => {
            snackbar.setSuccess("File saved");
            setSaving(false);
            onUpload(saved);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        if (user.roles.admin) {
            documentService.uploadDocument(userId, data.tag, data.file, success, failure);

        } else if (userId && userId !== user.id) {
            documentService.uploadSharedDocument(userId, data.tag, data.file, success, failure);

        } else {
            documentService.uploadMyDocument(data.tag, data.file, success, failure);
        }
    }

    return <Dialog
        open={true}
        aria-labelledby="document-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Upload file
        </ClosableDialogTitle>
        <DialogContent className="document-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <DocumentFields
                    data={data}
                    onChange={setData}
                    showTag={!tag}
                />}
        </DialogContent>
        {!saving ? <DialogActions>
            <Asterisk/>
            <Button color="primary"
                    disabled={!data.valid || !forms.differ(toData(), data)}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default DocumentDialog;
