import React, {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import DocumentCard from "../../cards/documentCard/documentCard";
import {arrays, forms, objects, strings} from "@atttomyx/shared-utils";
import {TAG_SEARCH} from "../../constants";

const FoundPatentFields = (props) => {
    const {snackbar, documentCache, user, userId, num, data, onChange: onSync} = props;
    const [document, setDocument] = useState(null);

    useEffect(() => {
        if (data.documentId) {
            documentCache.get(userId, (documents) => {
                const found = arrays.findEntity(documents, data.documentId) || {};

                setDocument(found);
            });
        }
    }, [userId, data.documentId]);

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = strings.isNotBlank(modified.id)
            && strings.isNotBlank(modified.citation)
            && strings.isNotBlank(modified.documentId);

        onSync(modified);
    }

    return <div className="found-patent-fields">
        <div className="field top-margin">
            <TextField label="Patent / publication number"
                       type="text"
                       value={forms.sanitizeValue(data.id)}
                       onChange={(event) => onChange("id", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Citation"
                       type="text"
                       multiline={true}
                       value={forms.sanitizeValue(data.citation)}
                       onChange={(event) => onChange("citation", event.target.value)}
            />
        </div>
        <DocumentCard
            snackbar={snackbar}
            user={user}
            userId={userId}
            num={num}
            document={document}
            tag={TAG_SEARCH}
            onSaveDocument={(saved) => {
                documentCache.onEntitySaved(userId, saved);
                onChange("documentId", saved.id);
            }}
        />
    </div>
}

export default FoundPatentFields;
