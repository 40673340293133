import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent} from "@mui/material";
import {Asterisk, ClosableDialogTitle} from "@atttomyx/react-components";
import TaskFields from "../../forms/taskFields/taskFields";
import * as taskService from "../../services/tasks";
import {forms} from "@atttomyx/shared-utils";

const toData = (task) => {
    return {
        dueOn: task.dueOn,
        userId: task.userId,
        valid: true,
    }
};

const TaskDialog = (props) => {
    const {snackbar, admins, task, onCancel, onSave} = props;
    const [data, setData] = useState(toData(task));
    const [saving, setSaving] = useState(false);

    const submitForm = () => {
        setSaving(true);

        const success = (saved) => {
            snackbar.setSuccess("Task saved");
            setSaving(false);
            onSave(saved);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        const modified = {
            userId: data.userId,
            onBehalfOfUserId: task.onBehalfOfUserId,
            title: task.title,
            description: task.description,
            dueOn: data.dueOn,
            dueAt: task.dueAt,
            completed: task.completed,
            type: task.type,
            metadata: task.metadata,
        };

        taskService.saveTask(task.id, modified, success, failure);
    }

    return <Dialog open={true}>
        <ClosableDialogTitle onClose={onCancel}>
            Task
        </ClosableDialogTitle>
        <DialogContent className="task-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <TaskFields
                    admins={admins}
                    data={data}
                    onChange={setData}
                />}
        </DialogContent>
        {!saving ? <DialogActions>
            <Asterisk/>
            <Button color="primary"
                    disabled={!data.valid || !forms.differ(toData(task), data)}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default TaskDialog;
