import React from "react";
import {Typography} from "@mui/material";
import QuestionsLink from "../questionsLink/questionsLink";

const QuestionsBlurb = () => {
    return <Typography paragraph={true}>
        Please contact us with any <QuestionsLink/>.
    </Typography>
};

export default QuestionsBlurb;
