import React, {useState} from "react";
import {AppBar, Toolbar} from "@mui/material";
import {UserAvatar} from "@atttomyx/react-components";
import "./header.css";

const Header = (props) => {
    const { dimensions, user, onLogout, moreMenu: MoreMenu, custom, color = "primary" } = props;
    const [ showMore, setShowMore ] = useState(false);

    const onClickUser = MoreMenu ? () => setShowMore(true) : undefined;

    return dimensions.short ?
        <React.Fragment/> :
        <div className="header">
            <AppBar position="fixed" color={color}>
                <Toolbar className="items">
                    {custom ?
                        <div className="custom">
                            {custom}
                        </div> : null}
                    <div className="spacer"/>
                    <div className="logged-in-user"
                         onClick={onClickUser}
                    >
                        <UserAvatar user={user} mode="left"/>
                    </div>
                </Toolbar>
            </AppBar>
            {showMore && MoreMenu ?
                <MoreMenu
                    user={user}
                    onCancel={() => setShowMore(false)}
                    onLogout={onLogout}
                /> : null}
        </div>
}

export default Header;
