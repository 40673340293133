import React, {useState} from "react";
import ServicesFields from "../../forms/servicesFields/servicesFields";
import UpsellMarketingStep from "../upsellMarketingStep/upsellMarketingStep";
import {Button} from "@mui/material";

const UpsellAnalysisStep = (props) => {
    const {snackbar, services, onChange, onCheckout, onSaveWizard} = props;
    const [showMarketing, setShowMarketing] = useState(false);

    return <>
        {showMarketing ?
            <UpsellMarketingStep
                snackbar={snackbar}
                onSaveWizard={onSaveWizard}
            /> : <>
            <ServicesFields
                data={services}
                onChange={onChange}
                upsellAnalysis={true}
            />
            <div className="actions">
                <Button
                    color="secondary"
                    variant="text"
                    onClick={() => setShowMarketing(true)}
                >
                    No Thanks
                </Button>
                <Button
                    color="primary"
                    onClick={onCheckout}
                    disabled={!services.valid}
                >
                    Checkout
                </Button>
            </div>
        </>}
    </>
};

export default UpsellAnalysisStep;
