import axios from 'axios';
import {sanitizeAccount} from "../utils/accounts";
import {sanitizeUser} from "../utils/users";

export const joinAccount = (user, success, failure) => {
    // need to skip the request interceptors (don't want to redirect to login on 401)
    const instance = axios.create();
    const url = "/api/v1/account/join";

    instance.post(url, {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        password: user.password,
    })
        .then(response => {
            const json = response.data;

            sanitizeAccount(json.account);
            sanitizeUser(json.user);

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const loadMyAccount = function(success, failure) {
    success({});
};

export const saveMyAccount = function(account, success, failure) {
    success({});
};
