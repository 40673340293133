import React from 'react';
import {Badge, Card, CardActions, CardContent, Divider, Typography} from "@mui/material";
import {CardData, CardTitle} from "@atttomyx/react-components";
import {arrays, datetime, strings} from "@atttomyx/shared-utils";
import {isDue} from "../../utils/tasks";
import "./taskCard.css";

const renderDescription = (description) => {
    const array = [];

    description.split("\n")
        .filter(strings.isNotBlank)
        .forEach((str) => {
            arrays.addTo(array, str);
        });

    return array;
};

const TaskCard = (props) => {
    const { task, filters, userRenderer, children } = props;
    let i = 0;

    return <Card className="task-card" raised={true}>
        <CardContent>
            <Badge
                color="error"
                variant="dot"
                invisible={!isDue(task)}
            >
                <div>
                    <CardTitle>
                        {task.title}
                    </CardTitle>
                    <Divider/>
                    <CardData
                        filters={filters}
                        data={task}
                        keyToMetadata={{
                            due: {
                                title: "Due",
                                getter: (data) => datetime.getPrettyDate(data.dueOn),
                            },
                            userId: {
                                title: "Assigned to",
                                formatter: userRenderer.render,
                                hidden: !task.userId,
                            },
                            completed: {
                                title: "Completed",
                                formatter: datetime.getFullDateAndTime,
                                hidden: !task.completed,
                            },
                        }}
                    />
                    {task.description ? <>
                        <Divider/>
                        <div className="description">
                            {renderDescription(task.description).map(line =>
                                <Typography key={"line_" + i++} variant="body2">
                                    {line}
                                </Typography>)}
                        </div>
                    </> : null}
                </div>
            </Badge>
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
    </Card>
}

export default TaskCard;
