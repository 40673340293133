import React from "react";
import {Fireworks} from "@fireworks-js/react";
import {Typography} from "@mui/material";
import QuestionsBlurb from "../../components/questionsBlurb/questionsBlurb";
import {FIREWORKS_OPTIONS, FIREWORKS_STYLE} from "../../constants";

const DoneStep = () => {
    return <>
        <Typography variant="h5" paragraph={true}>
            Thank you!
        </Typography>
        <QuestionsBlurb/>
        <Typography>
            Keep innovating.
        </Typography>
        <Fireworks
            options={FIREWORKS_OPTIONS}
            style={FIREWORKS_STYLE}
        />
    </>
};

export default DoneStep;
