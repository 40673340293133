import React, {useEffect} from "react";
import {CircularProgress} from "@mui/material";
import {useQuery} from "@atttomyx/react-hooks";

const WelcomeNewPage = (props) => {
    const { snackbar, authService, onToLogin, onLogin } = props;
    const query = useQuery();

    useEffect(() => {
        const email = query.get("email");
        const password = query.get("password");

        const failure = (err) => {
            snackbar.setWarning("Please login");
            onToLogin();
        }

        if (email && password) {
            authService.login(email, password, null, onLogin, failure);

        } else {
            failure("missing params");
        }
    }, []);

    return <CircularProgress size="80px"/>
}

export default WelcomeNewPage;
