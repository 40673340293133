import React from "react";
import {withRouter} from "react-router-dom";
import {Link} from "@mui/material";
import {router} from "@atttomyx/react-utils";
import {PAGE_DOCUMENTS} from "../../constants";

const YourFilesLink = (props) => {
    const { history } = props;

    return <Link onClick={() => router.redirectTo(history, PAGE_DOCUMENTS)}>your files</Link>
};

export default withRouter(YourFilesLink);
